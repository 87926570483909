import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parse } from 'query-string';
import ReactTooltip from 'react-tooltip';

import { useClassnames } from 'hook/use-classnames';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { normalizeObject } from 'component/helper/normalize-object';
import InputClubs from 'component/form/input-clubs';
import InputTime from 'component/form/input-time';
import Checkbox from 'component/form/checkbox';
import UI from 'component/ui';
import InputColor from 'component/form/input-color';
import Input from 'component/form/input';
import InputInterval from 'component/form/input-interval';
import Button from 'component/button';
import Form from 'component/form';
import InputRange from 'component/form/input-range';
import InputLocation from 'component/form/input-location';
import InputEvents from 'component/form/input-events/index-tsx-async';
import InputTimeRange from 'component/form/input-time-range';
import InputYears from 'component/form/input-years';

import { IProps } from './types';
import style from './styles.pcss';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const FilterForm: FC<IProps> = (props) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    const elColorInput = () => {
        let defaultValue = queryParams.color_ids;

        if (Array.isArray(queryParams.color_ids)) {
            defaultValue = queryParams.color_ids.map(Number);
        }

        return (
            <InputColor
                registry={props.registry.field}
                name="color"
                children={t('global.form.items.colors')}
                direction="column"
                className={cn('filter-form__input-block')}
                defaultValue={defaultValue}
            />
        );
    };

    const elAlbumYearInput = () => {
        const defaultYear = queryParams.album_year || localStorage.getItem('album_year');

        return (
            <InputYears
                registry={props.registry.field}
                name="album_year"
                clearable={true}
                defaultValue={defaultYear && {label: defaultYear, value: defaultYear}}
                children={t('components.sidebar-action.forms.items.album-year')}
                className={cn('filter-form__input-block')}
                direction="column"
            />
        );
    };

    const elSearchInput = () => {
        return (
            <div className={cn('filter-form__search')}>
                <ReactTooltip
                    id="search"
                    place="left"
                    effect="solid"
                    border={true}
                    borderColor="#dee1e4"
                    backgroundColor="#fff"
                    className={cn('filter-form__search__sale-tooltip')}
                >
                    Поиск персоны осуществляется <b>по имени, фамилии или ID</b>
                </ReactTooltip>
                <span className={cn('filter-form__search__sale-icon')}>
                    <FontAwesomeIcon data-tip={true} data-type="light" data-for="search" icon={faQuestionCircle} />
                </span>
                <Input
                    registry={props.registry.field}
                    name="search"
                    defaultValue={queryParams.search}
                    type="text"
                    direction="column"
                    children={'Поиск'}
                    className={cn('filter-form__input-block')}
                />
            </div>
        );
    };

    const elPersonNumberInput = () => {
        return (
            <Input
                registry={props.registry.field}
                name="sport_number"
                defaultValue={queryParams.number}
                type="text"
                direction="column"
                children={t('global.form.items.sport_number')}
                className={cn('filter-form__input-block')}
            />
        );
    };

    const elTimeRangeInput = () => {
        return (
            <InputTimeRange
                registry={props.registry.field}
                timeFrom={queryParams.time_from ? queryParams.time_from : undefined}
                timeTo={queryParams.time_to ? queryParams.time_to : undefined}
                name="timeRange"
                label={'Период фотосъемки'}
            />
        );
    };

    const elNameInput = () => {
        return (
            <Input
                registry={props.registry.field}
                name="name"
                defaultValue={queryParams.search}
                type="text"
                direction="column"
                children={t('global.form.items.person')}
                className={cn('filter-form__input-block')}
            />
        );
    };

    const elEventDateInput = () => {
        return (
            <InputInterval
                registry={props.registry.field}
                name="event_date_range"
                firstDate={queryParams.event_date_from ? new Date(queryParams.event_date_from) : undefined}
                secondDate={queryParams.event_date_to ? new Date(queryParams.event_date_to) : undefined}
                children={'Период события'}
                direction="column"
                className={cn('filter-form__input-block')}
            />
        );
    };

    const elPhotoDateInput = () => {
        return (
            <InputInterval
                registry={props.registry.field}
                name="photo_date_range"
                firstDate={queryParams.date_from ? new Date(queryParams.date_from) : undefined}
                secondDate={queryParams.date_to ? new Date(queryParams.date_to) : undefined}
                children={'Период фото'}
                direction="column"
                className={cn('filter-form__input-block')}
            />
        );
    };

    const elCommentInput = () => {
        let defaultComment = null;

        if(queryParams.club_id && queryParams.club_name) {
            defaultComment = {
                value: queryParams.club_id,
                label: queryParams.club_name
            };
        }

        return (
            <InputClubs
                registry={props.registry.field}
                name="club"
                defaultValue={defaultComment}
                clearable={true}
                children={t('global.form.items.club')}
                direction="column"
                className={cn('filter-form__input-block')}
            />
        );
    };

    const elTimeInput = () => {
        return (
            <InputTime
                registry={props.registry.field}
                label={'Время фотосъемки'}
                defaultValue={queryParams.time}
                name="time"
                direction="column"
            />
        );
    };

    const elStockPhotos = () => {
        return (
            <Checkbox
                className={cn('filter-form__checkbox')}
                registry={props.registry.field}
                name="is_stock"
                defaultValue={queryParams.is_stock}
                children={'фото из stock'}
            />
        );
    };

    const elPrice = () => {
        return (
            <InputRange
                registry={props.registry.field}
                minValue={0}
                maxValue={10000000}
                // className={cn('form__input-block')}
                className={cn('filter-form__input-block')}
                name="price_range"
                direction="column"
                children={t('global.form.items.cost')}
                value_from={queryParams.price_from}
                value_to={queryParams.price_to}
            />
        );
    };

    const elLocation = () => {
        let defaultLocation = null;

        if(queryParams.location_id && queryParams.location_name) {
            defaultLocation = {
                value: queryParams.location_id,
                label: queryParams.location_name
            };
        }

        return (
            <InputLocation
                registry={props.registry.field}
                name="location"
                className={cn('filter-form__input-block')}
                children={t('global.form.items.city')}
                direction="column"
                defaultValue={defaultLocation}
            />
        );
    };

    const elEvent = () => {
        return (
            <InputEvents
                registry={props.registry.field}
                name="event"
                clearable={true}
                children={t('global.form.items.event')}
                direction="column"
                className={cn('filter-form__input-block')}
                default_id={queryParams?.event_id}
            />
        );
    };

    return (
        <UI.Box padding={true} className={cn('filter-form')}>
            {/*<UI.BoxHeader>{t('route.persons.sidebar.header')}</UI.BoxHeader>*/}
            <Form
                registry={props.registry.form}
                onChange={props.onChange}
                onChangeValidity={props.onChangeValidity}
            >
                {props.children}
                {props.albumYear && elAlbumYearInput()}
                {props.color && elColorInput()}
                {props.search && elSearchInput()}
                {props.personNumber && elPersonNumberInput()}
                {props.timeRange && elTimeRangeInput()}
                {props.name && elNameInput()}
                {props.location && elLocation()}
                {props.event && elEvent()}
                {props.comment && elCommentInput()}
                {props.eventDate && elEventDateInput()}
                {props.photoDate && elPhotoDateInput()}
                {props.price && elPrice()}
                {props.stockPhotos && elStockPhotos()}
                <div className={cn('filter-form__btn')}>
                    <Button
                        onClick={props.onReset}
                        type="reset"
                    >
                        Сбросить
                    </Button>
                </div>
            </Form>
        </UI.Box>
    );
};

FilterForm.defaultProps = {
    price        : false,
    location     : false,
    event        : false,
    color        : false,
    personNumber : false,
    name         : false,
    comment      : false,
    eventDate    : false,
    photoDate    : false,
    stockPhotos  : false,
    albumYear    : false,
    timeRange    : false
};

export default FilterForm;
