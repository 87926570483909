import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserPlus,
    faPenSquare,
    faWindowClose,
    faEdit,
    faTrashAlt,
    faPlusSquare
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { bulkEditPhoto } from 'component/api/photo';
import { IValue } from 'component/form/input-clubs/types';
import { useCancelTokens } from 'component/core/cancel-token';
import { ISelectedMap } from 'route/dashboard/photos/types';
import { Redirect } from 'react-router-dom';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import { key as keyUser } from 'store/reducers/user/reducer';
import UI from 'component/ui';
import Button from 'component/button';
import { useRegistry } from 'component/form';
import { useClassnames } from 'hook/use-classnames';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { getAlbumById } from 'component/api/album';
import { DataAlbumsItem } from 'component/api/types/api/photo/album/get-album-list/get/code-200';
import Modal from 'component/modal';
import AlbumModal from 'component/modal/album';
import AlbumAccess from 'component/modal/album-access';
import SideAction from 'component/wireframes/side-action';
import ButtonCircular from 'component/button/circular';
import ActionButton from 'component/button/circular/action-button';
import { useAlert } from 'component/alert/provider';
import {
    DataFilesTmpFacesItem,
    DataFilesTmpFacesPersonsItem
} from 'component/api/types/api/tmp-file/get-files-list/get/code-200';

import style from './index.pcss';
import { IPayload } from 'component/form/types';
import { sendModerationPhotos } from 'component/api/moderation';
import Loader from 'component/loader';
import PhotoSaleModal from 'component/modal/sale';
import TarifForm from 'route/album/tarif-form';
import CustomAlert from 'component/custom-alert';
import api from 'src/api';
import { AlbumItem, AlbumPhoto, GetAlbumPhotoData } from 'src/api/albums/types';
import { Page } from 'src/api/base';
import newSortPhotosList, { ISortedMap } from 'component/helper/new-sort-photos-list';
import NewPhotoList from 'component/photo-list/new-photo-list';

const PHOTOS_LIMIT = 10;

const Album = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { field, form } = useRegistry();
    const { id }: { id: string } = useParams();
    const history = useHistory();
    const { show, hide } = useAlert();

    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const isMobile = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].tablet);

    const [photoWithFaceMap, setPhotoWithFaceMap] = useState<ISortedMap | null>(null);
    const [photoWithoutFaceMap, setPhotoWithoutFaceMap] = useState<ISortedMap | null>(null);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

    const [idsWithFaces, setIdsWithFaces] = useState<ISelectedMap>({});
    const [idsWithoutFaces, setIdsWithoutFaces] = useState<ISelectedMap>({});
    const refIdsWithFaces = useRef<ISelectedMap>(idsWithFaces);
    const refIdsWithoutFaces = useRef<ISelectedMap>(idsWithoutFaces);
    const [isModerationBtn, setIsModerationBtn] = useState<boolean>(false);
    const [idsList, setIdsList] = useState<Array<number>>([]);

    const [validity, setValidity] = useState<boolean>(false);
    const [token, tokenWithFace, tokenWithoutFace, tokenAlbum, tokenEdit] = useCancelTokens(5);
    const [album, setAlbum] = useState<AlbumItem | null>(null);
    const [oldAlbum, setOldAlbum] = useState<DataAlbumsItem | null>(null);
    const [resetIdsList, setResetIdsList] = useState<boolean>(false);
    const refReset = useRef<boolean>(resetIdsList);

    const registry = useRegistry();
    const [cancelToken, tokenEditUser, tokenUserSelfInfo] = useCancelTokens(3);
    const [pending, setPending] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [modalForm, setModalForm] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<boolean>(false);
    const [modalAccess, setModalAccess] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [photoSaleModal, setPhotoSaleModal] = useState<boolean>(false);

    const [photosWithFaceList, setPhotosWithFaceList] = useState<Array<AlbumPhoto>>([]);
    const [photosWithFaceTotal, setPhotosWithFaceTotal] = useState<number>(0);
    const [photosWithFacePage, setPhotosWithFacePage] = useState<number>(1);
    const [isPhotosWithFaceNext, setIsPhotosWithFaceNext] = useState<boolean>(true);
    const [isPhotosWithFaceLoading, setIsPhotosWithFaceLoading] = useState<boolean>(false);
    const [isPhotosWithFaceLoadMore, setIsPhotosWithFaceLoadMore] = useState<boolean>(false);

    const [photosWithoutFaceList, setPhotosWithoutFaceList] = useState<Array<AlbumPhoto>>([]);
    const [photosWithoutFaceTotal, setPhotosWithoutFaceTotal] = useState<number>(0);
    const [photosWithoutFacePage, setPhotosWithoutFacePage] = useState<number>(1);
    const [isPhotosWithoutFaceNext, setIsPhotosWithoutFaceNext] = useState<boolean>(true);
    const [isPhotosWithoutFaceLoading, setIsPhotosWithoutFaceLoading] = useState<boolean>(false);
    const [isPhotosWithoutFaceLoadMore, setIsPhotosWithoutFaceLoadMore] = useState<boolean>(false);

    const [tarifModal, setTarifModal] = useState<boolean>(false);
    const tarifRegistry = useRegistry();

    if(!userId) {
        return <Redirect to="/login" />;
    }

    const _requestAlbum = useCallback(() => {
        api.albums.getAlbumRetrieve(Number(id))
            .then((resp) => {
                if (resp.data) {
                    getAlbumById({
                        cancelToken: tokenAlbum.new(),
                        params     : {
                            album_id: id
                        }
                    })
                        .then((payload) => {
                            setOldAlbum(payload);
                        });

                    setAlbum(resp.data);
                }
            })
            .catch(() => {
                history.push('/404');
            });

    }, []);

    const isEditRight = (): boolean => {
        return Boolean(oldAlbum?.user_rights.find((el) => el === 'WRITE'));
    };

    const updPhotoInfo = useCallback((photo_data) => {
        bulkEditPhoto({
            cancelToken: tokenEdit.new(),
            data       : {
                photo_ids: idsList,
                album_id: id,
                photo_data
            }
        })
            .then(() => {
                if ('album_id' in photo_data || 'is_deleted' in photo_data) {
                    setResetIdsList(true);
                    setIdsList([]);
                }

                setPhotosWithFacePage(1);
                setIsPhotosWithFaceNext(true);
                setIsPhotosWithFaceLoading(true);

                setPhotosWithoutFacePage(1);
                setIsPhotosWithoutFaceNext(true);
                setIsPhotosWithoutFaceLoading(true);

                onShowAlert('success', 'Ваши изменения успешно применены.');
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    onShowAlert('warning', 'Не удалось применить Ваши изменения.');
                }
            });
    }, [idsList]);

    const onChangeForm = debounce(useCallback(() => {
        if(form.checkValidity()) {
            setPhotosWithFacePage(1);
            setIsPhotosWithFaceNext(true);
            setIsPhotosWithFaceLoading(true);

            setPhotosWithoutFacePage(1);
            setIsPhotosWithoutFaceNext(true);
            setIsPhotosWithoutFaceLoading(true);
        }
    }, [validity]), 300);

    const onSubmit = useCallback(() => {
        setPending(true);
        const fields = registry.form.getFields();

        if (fields) {
            const fieldName = fields.get('album_name');
            const fieldDescription = fields.get('album_description');
            const fieldPrivate = fields.get('album_private');
            const fieldPrepaid = fields.get('album_prepaid');
            const fieldVideo = fields.get('album_video');

            if (fieldName && fieldDescription) {

                if (fieldName.value === t('global.reserved-names.my-photo')) {
                    setError(t('global.massage.reserved-name'));
                    setPending(false);
                } else {
                    const videoUpdateData = {
                        name: String(fieldName.value),
                        description: String(fieldDescription.value) ? fieldDescription.value : null,
                        is_private: fieldPrivate?.value,
                        is_prepaid: fieldPrepaid?.value,
                        is_video: fieldVideo?.value
                    };
                    api.albums.partialUpdateAlbum(Number(id), videoUpdateData)
                        .then((resp) => {
                            setMessage(t('route.album.modal.message-ok'));
                            setModalForm(false);
                            setPending(false);
                            _requestAlbum();
                            onShowAlert('success', 'Ваши изменения успешно применены.');
                        })
                        .catch((err) => {
                            if(!axios.isCancel(err)) {
                                setPending(false);
                                onShowAlert('', '', true);
                            }
                        });
                }
            }
        }
    }, [modalForm]);

    const onClickModal = useCallback(() => {
        setModalForm(true);
    }, [modalForm, modalMessage]);

    const onClickCloseModal = (): void => {
        setModalForm(false);
        setModalAccess(false);
    };

    const onShowAlert = (type?: string, text?: string, isCustom?: boolean) => {
        if (type && text && !isCustom) {
            setShowWarningModal(false);
            show(text, type);
        }
        if (isCustom) {
            hide();
            setShowWarningModal(true);
        }

    };

    const elModal = useMemo(() => {
        if (modalForm && album) {
            return (
                <AlbumModal
                    title={t('route.album.modal.title')}
                    album={album}
                    registry={registry}
                    onClickClose={onClickCloseModal}
                    onSubmitClick={onSubmit}
                />
            );
        }
    }, [error, modalForm]);

    const elModalAccess = useMemo(() => {
        if (modalAccess && album) {
            return (
                <AlbumAccess
                    albumId={album.id}
                    onClickClose={onClickCloseModal}
                />
            );
        }
    }, [modalAccess]);

    const elTarifModal = useMemo(() => {
        if (tarifModal && !oldAlbum?.is_active_subscription) {
            const closeModal = () => setTarifModal(false);

            return (
                <Modal onClickClose={closeModal}>
                    <TarifForm
                        registry={tarifRegistry}
                        albumId={id}
                        onSubmit={closeModal}
                    />
                </Modal>
            );
        }
    }, [tarifModal]);

    const onClickDelete = (): void => {
        if (photosWithFaceTotal || photosWithoutFaceTotal) {
            setMessage(t('route.album.message.del-error'));
            setModalMessage(true);
        } else {
            api.albums.destroyAlbum(Number(id))
                .then(() => {
                    history.push('/dashboard/photos');
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                    }
                });
        }
    };

    const onClickAccess = (): void => {
        setModalAccess(true);
    };

    const elButtonAccess = useMemo(() => {
        if (album && album.is_private) {
            return (
                <Button
                    isSecondary={true}
                    className={cn('album__sidebar_button')}
                    onClick={onClickAccess}
                >
                    <div className={cn('album__sidebar_button-text')}>
                        {t('route.album.button-album-access')}
                    </div>
                </Button>
            );
        }
    }, [album]);

    const elSidebarButton = useMemo(() => {
        return (
            <UI.Box padding={true} className={cn('album__sidebar_box')}>
                <Fragment>
                    <Button
                        to={album?.is_video ? `/upload-video?album_id=${id}` : `/upload-photo`}
                        className={cn('album__sidebar_button')}
                    >
                        <div className={cn('album__sidebar_button-icon')}>
                            <FontAwesomeIcon icon={faPlusSquare} />
                        </div>
                        <div className={cn('album__sidebar_button-text')}>
                            {album?.is_video ? `
                            ${t('global.button.video-upload')}
                            ` : `
                            ${t('global.button.photo-upload')}
                            `}
                        </div>
                    </Button>
                    <Button
                        isSecondary={true}
                        className={cn('album__sidebar_button')}
                        onClick={onClickModal}
                    >
                        <div className={cn('album__sidebar_button-icon')}>
                            <FontAwesomeIcon icon={faPenSquare} />
                        </div>
                        <div className={cn('album__sidebar_button-text')}>
                            {t('route.album.button-album-update')}
                        </div>
                    </Button>
                    <Button
                        isSecondary={true}
                        className={cn('album__sidebar_button')}
                        onClick={onClickDelete}
                    >
                        <div className={cn('album__sidebar_button-icon')}>
                            <FontAwesomeIcon icon={faWindowClose} />
                        </div>
                        <div className={cn('album__sidebar_button-text')}>
                            {t('route.album.button-album-delete')}
                        </div>
                    </Button>
                </Fragment>
                <Button
                    className={cn('album__sidebar_button')}
                    onClick={() => setPhotoSaleModal(true)}
                >
                    Скидки
                </Button>
                {elButtonAccess}
            </UI.Box>
        );
    }, [album]);

    const elSettingsBtn = useMemo(() => {
        if (isMobile || isTablet) {
            return (
                <ActionButton
                    className={cn('album__btn-setting')}
                >
                    <ButtonCircular
                        text={'Редактировать альбом'}
                        isSmall={true}
                        onClick={onClickModal}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </ButtonCircular>
                    <ButtonCircular
                        text={'Удалить альбом'}
                        isSmall={true}
                        onClick={onClickDelete}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </ButtonCircular>
                    {album && album.is_private && (
                        <ButtonCircular
                            text={'Предоставить доступ'}
                            isSmall={true}
                            onClick={onClickDelete}
                        >
                            <FontAwesomeIcon icon={faUserPlus} />
                        </ButtonCircular>
                    )}
                </ActionButton>
            );
        }
    }, [album]);

    useEffect(() => {
        return () => {
            tokenWithFace.remove();
            tokenWithoutFace.remove();
            token.remove();
            tokenAlbum.remove();
            tokenEdit.remove();
            cancelToken.remove();
            tokenEditUser.remove();
            tokenUserSelfInfo.remove();
        };
    }, []);

    useEffect(() => {
        refIdsWithoutFaces.current = idsWithoutFaces;
        refIdsWithFaces.current = idsWithFaces;

        const photosWithFaces =  Object.keys(refIdsWithFaces.current).reduce((accum: Array<number>, current: string) => {
            return accum.concat(refIdsWithFaces.current[Number(current)]);
        }, []);
        const photosWithoutFaces =  Object.keys(refIdsWithoutFaces.current).reduce((accum: Array<number>, current: string) => {
            return accum.concat(refIdsWithoutFaces.current[Number(current)]);
        }, []);

        setIsModerationBtn(Boolean(photosWithoutFaces.length));
        setIdsList([...photosWithFaces, ...photosWithoutFaces]);
    }, [JSON.stringify(idsWithFaces), JSON.stringify(idsWithoutFaces)]);

    useEffect(() => {
        refReset.current = resetIdsList;
    }, [resetIdsList]);

    useEffect(() => {
        _requestAlbum();
    }, []);

    useEffect(() => {
        if (isPhotosWithFaceLoading || isPhotosWithFaceLoadMore) {
            const page: Page = {
                pageNumber: photosWithFacePage,
                pageSize: PHOTOS_LIMIT
            };

            const formParams = getFormParams(form.getPayload());

            const params: GetAlbumPhotoData = {
                is_faces_detected: true,
                ...(formParams.number && { number_id: formParams.number }),
                ...(formParams.color_ids && { color_id: formParams.color_ids }),
                ...(formParams.time_from && { time_after: formParams.time_from }),
                ...(formParams.time_to && { time_before: formParams.time_to }),
                ...(formParams.person_name && { search: formParams.person_name }),
                ...(formParams.date_from && { date_after: formParams.date_from }),
                ...(formParams.date_end && { date_before: formParams.date_end }),
                ...(formParams.price_from && { price_min: formParams.price_from }),
                ...(formParams.price_to && { price_max: formParams.price_to })
            };

            api.albums.getAlbumPhotoList(Number(id), page, params)
                .then((resp) => {
                    const newPhotos = isPhotosWithFaceLoadMore ? [...photosWithFaceList, ...resp.data.results] : resp.data.results;
                    if (newPhotos.length) {
                        setPhotoWithFaceMap(newSortPhotosList(newPhotos));
                    } else {
                        setPhotoWithFaceMap(null);
                    }

                    setPhotosWithFaceList(newPhotos);
                    setPhotosWithFaceTotal(resp.data.count);
                    setIsPhotosWithFaceLoadMore(false);
                    setIsPhotosWithFaceLoading(false);
                })
                .catch(() => {
                    setIsPhotosWithFaceLoadMore(false);
                    setIsPhotosWithFaceLoading(false);
                });
        }
    }, [isPhotosWithFaceLoading, isPhotosWithFaceLoadMore]);

    useEffect(() => {
        if (isPhotosWithoutFaceLoading || isPhotosWithoutFaceLoadMore) {
            const page: Page = {
                pageNumber: photosWithoutFacePage,
                pageSize: PHOTOS_LIMIT
            };

            const formParams = getFormParams(form.getPayload());

            const params: GetAlbumPhotoData = {
                is_faces_detected: false,
                ...(formParams.number && { number_id: formParams.number }),
                ...(formParams.color_ids && { color_id: formParams.color_ids }),
                ...(formParams.time_from && { time_after: formParams.time_from }),
                ...(formParams.time_to && { time_before: formParams.time_to }),
                ...(formParams.person_name && { search: formParams.person_name }),
                ...(formParams.date_from && { date_after: formParams.date_from }),
                ...(formParams.date_end && { date_before: formParams.date_end }),
                ...(formParams.price_from && { price_min: formParams.price_from }),
                ...(formParams.price_to && { price_max: formParams.price_to })
            };

            api.albums.getAlbumPhotoList(Number(id), page, params)
                .then((resp) => {
                    const newPhotos = isPhotosWithoutFaceLoadMore ? [...photosWithoutFaceList, ...resp.data.results] : resp.data.results;
                    if (newPhotos.length) {
                        setPhotoWithoutFaceMap(newSortPhotosList(newPhotos));
                    } else {
                        setPhotoWithoutFaceMap(null);
                    }

                    setPhotosWithoutFaceList(newPhotos);
                    setPhotosWithoutFaceTotal(resp.data.count);
                    setIsPhotosWithoutFaceLoadMore(false);
                    setIsPhotosWithoutFaceLoading(false);
                })
                .catch(() => {
                    setIsPhotosWithoutFaceLoadMore(false);
                    setIsPhotosWithoutFaceLoading(false);
                });
        }
    }, [isPhotosWithoutFaceLoading, isPhotosWithoutFaceLoadMore]);

    const _moderationPhotos = () => {
        const photosWithoutFaces =  Object.keys(idsWithoutFaces).reduce((accum: Array<number>, current: string) => {
            return accum.concat(idsWithoutFaces[Number(current)]);
        }, []);

        if (Boolean(photosWithoutFaces.length)) {
            sendModerationPhotos({
                data: {
                    photo_ids: photosWithoutFaces
                }
            })
                .then(() => {
                    onShowAlert('success', 'Выбранные фотографии отправлены на модерацию.');
                })
                .catch((err) => {
                    console.error(err);
                    onShowAlert('warning', 'Не удалось отправить заявку на модерацию.');
                });
        }
    };

    const onClickModerationPhotos = useCallback(() => {
        _moderationPhotos();
    }, [idsWithoutFaces]);

    const onChangeSelectedWithFaces = useCallback((index: number) => (ids: Array<number>) => {
        const newList = { ...refIdsWithFaces.current };
        newList[index] = ids;

        setIdsWithFaces(newList);
    }, []);

    const onChangeSelectedWithoutFaces = useCallback((index: number) => (ids: Array<number>) => {
        const newList = { ...refIdsWithoutFaces.current };
        newList[index] = ids;

        setIdsWithoutFaces(newList);
    }, []);

    const getFormParams = (data: IPayload) => {
        const faces = data.photo?.tmp_faces;
        let personsNew: Array<DataFilesTmpFacesPersonsItem> = [];

        if(faces?.length) {
            personsNew = faces.reduce((acc: Array<DataFilesTmpFacesPersonsItem>, curr: DataFilesTmpFacesItem) => {
                if(curr.persons?.length) {
                    curr.persons.forEach((person) => {
                        const isExist = acc.find((item) => item.id === person.id);

                        if(person.id && !isExist) {
                            acc.push(person);
                        }
                    });
                }

                return acc;
            }, []);
        }

        return {
            ...(personsNew.length && { person_ids: personsNew.map((item) => item.id) }),
            ...(data.name && { person_name: data.name }),
            ...(data.location?.value && { location_id: data.location?.value }),
            ...(data.event?.value && { event_id: data.event.value }),
            ...(data.price_range?.value_from && { price_from: data.price_range?.value_from }),
            ...(data.price_range?.value_to && { price_to: data.price_range?.value_to }),
            ...(data.event_date_range?.date_from && { date_from: data.event_date_range?.date_from }),
            ...(data.event_date_range?.date_to && { date_end: data.event_date_range?.date_to }),
            ...(data.clubs?.length && { club_ids: data.clubs.map((club: IValue) => club.value) }),
            ...(data.color && { color_ids: data.color }),
            ...(data.sport_number && { number: data.sport_number }),
            ...(data.time && { time: data.time }),
            ...(data.timeRange?.valueFrom && { time_from: data.timeRange.valueFrom }),
            ...(data.timeRange?.valueTo && { time_to: data.timeRange.valueTo })
        };
    };

    const elPhotosWithFaces = useMemo(() => {
        if (isPhotosWithFaceLoading) {
            return <Loader />;
        }

        if (photoWithFaceMap) {
            const keys = Object.keys(photoWithFaceMap);

            return keys.map((key, index) => {
                return (
                    <div key={index}>
                        <NewPhotoList
                            elControls={isEditRight()}
                            dateLink={true}
                            list={photoWithFaceMap[key]}
                            onChangeSelectedList={onChangeSelectedWithFaces(index)}
                            resetSelected={resetIdsList}
                        />
                    </div>
                );
            });
        }

        return <div className={cn('album__empty')}>Фото не найдены</div>;
    }, [JSON.stringify(photoWithFaceMap), resetIdsList, isPhotosWithFaceLoading]);

    const elPhotosWithoutFaces = useMemo(() => {
        if (isPhotosWithoutFaceLoading) {
            return <Loader />;
        }

        if (photoWithoutFaceMap) {
            const keys = Object.keys(photoWithoutFaceMap);

            return keys.map((key, index) => {
                return (
                    <div key={index}>
                        <NewPhotoList
                            elControls={isEditRight()}
                            dateLink={true}
                            list={photoWithoutFaceMap[key]}
                            onChangeSelectedList={onChangeSelectedWithoutFaces(index)}
                            resetSelected={resetIdsList}
                        />
                    </div>
                );
            });
        }

        return <div className={cn('album__empty')}>Фото не найдены</div>;
    }, [JSON.stringify(photoWithoutFaceMap), resetIdsList, isPhotosWithoutFaceLoading]);

    const onClickMorePhotoWithFace = (): void => {
        if (isPhotosWithFaceNext) {
            setPhotosWithFacePage((prev) => prev + 1);
            setIsPhotosWithFaceLoadMore(true);
        }
    };

    const elMorePhotoWithFace = () => {
        if(!isPhotosWithFaceLoading && photosWithFaceList?.length) {
            if (!(photosWithFaceList?.length === photosWithFaceTotal)) {
                return (
                    <div className={cn('photos__btn-more')}>
                        <Button
                            disabled={isPhotosWithFaceLoadMore}
                            isLoading={isPhotosWithFaceLoadMore}
                            isSecondary={true}
                            onClick={onClickMorePhotoWithFace}
                        >
                            Загрузить ещё
                        </Button>
                    </div>
                );
            }
        }
    };

    const onClickMorePhotoWithoutFace = (): void => {
        if (isPhotosWithoutFaceNext) {
            setPhotosWithoutFacePage((prev) => prev + 1);
            setIsPhotosWithoutFaceLoadMore(true);
        }
    };

    const onClickHideWarningModal = () => {
        setShowWarningModal(false);
    };

    const elWarningModal = useMemo(() => {
        if (showWarningModal) {
            return (
                <CustomAlert onClick={onClickHideWarningModal} />
            );
        }
    }, [showWarningModal]);

    const elMorePhotoWithoutFace = () => {
        if(!isPhotosWithoutFaceLoading && photosWithoutFaceList?.length) {
            if (!(photosWithoutFaceList?.length === photosWithoutFaceTotal)) {
                return (
                    <div className={cn('photos__btn-more')}>
                        <Button
                            disabled={isPhotosWithoutFaceLoadMore}
                            isLoading={isPhotosWithoutFaceLoadMore}
                            isSecondary={true}
                            onClick={onClickMorePhotoWithoutFace}
                        >
                            Загрузить ещё
                        </Button>
                    </div>
                );
            }
        }
    };

    const elPhotoSaleModal = useMemo(() => {
        if (photoSaleModal) {
            return (
                <PhotoSaleModal
                    albumId={Number(id)}
                    registry={registry}
                    onClickClose={() => setPhotoSaleModal(false)}
                />
            );
        }
    }, [photoSaleModal]);

    return (
        <SideAction
            title={album?.name ? album.name : ''}
            content={(
                <div>
                    {elSettingsBtn}
                    <section className={cn('album__top')}>
                        <UI.BoxHeader className={cn('album__top-header')}>
                            Фотографии без лиц (требуют модерации) {photosWithoutFaceTotal !== 0 && <span>{photosWithoutFaceTotal} фото</span>}
                        </UI.BoxHeader>
                        <UI.Main className={cn('album__top-content')}>
                            <UI.Box padding={true} className={cn('album__content-box')}>
                                {isModerationBtn && (
                                    <div className={cn('album__moderation-btn')}>
                                        <Button onClick={onClickModerationPhotos}>Отправить на модерацию</Button>
                                    </div>
                                )}
                                {elPhotosWithoutFaces}
                                {elMorePhotoWithoutFace()}
                            </UI.Box>
                        </UI.Main>
                    </section>
                    <section className={cn('album__top')}>
                        <UI.BoxHeader className={cn('album__top-header')}>
                            Фотографии для продажи {photosWithFaceTotal !== 0 && <span>{photosWithFaceTotal} фото</span>}
                        </UI.BoxHeader>
                        <UI.Main className={cn('album__top-content')}>
                            <UI.Box padding={true} className={cn('album__content-box')}>
                                {elPhotosWithFaces}
                                {elMorePhotoWithFace()}
                            </UI.Box>
                        </UI.Main>
                    </section>
                </div>
            )}
            sidebar={elSidebarButton}
            idsList={idsList}
            form={form}
            field={field}
            isSearchPerson={true}
            color={true}
            personNumber={true}
            update={updPhotoInfo}
            isPrice={true}
            isName={true}
            isTimeRange={true}
            onChangeForm={onChangeForm}
            onChangeValidity={setValidity}
        >
            {elWarningModal}
            {elModal}
            {elModalAccess}
            {elPhotoSaleModal}
            {elTarifModal}
        </SideAction>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Album;
