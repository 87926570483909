import React, { useState, useMemo, useEffect, useCallback } from 'react';
import style from './index.pcss';
import { IProps } from './types';
import { parse } from 'query-string';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { normalizeObject } from 'component/helper/normalize-object';
import { useClassnames } from 'hook/use-classnames';
import { useTranslation } from 'react-i18next';
import Avatar from 'component/avatar';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import { useHistory } from 'react-router';
import PersonControlIcons from '../control-icons';
import api from 'src/api';
import { Person } from 'src/api/persons/types';
import PersonEdit from 'component/person-edit';
import PersonMerge from 'component/person-merge';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

export const PersonPhotoItem = (props: IProps) => {
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const userId = useSelector<IStore>((storeApp) => storeApp[keyUser].id);

    const [person, setPerson] = useState<Person | null>();
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showMergeModal, setShowMergeModal] = useState<boolean>(false);

    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        api.persons.getPersonItem(Number(props.item.id))
            .then((resp: {data: Person}) => {
                setPerson(resp.data);
            });
    }, [props.event]);

    const onClickEditPerson = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/persons/photos/${props.id}${queryParams.event_id ? `?event_id=${queryParams.event_id}` : ''}`);

            return;
        }

        setShowEditModal(true);
    }, [isAuth]);

    const onClickMergePerson = useCallback(() => {
        if (!isAuth) {
            history.push(`/login?from=/persons/photos/${props.id}${queryParams.event_id ? `?event_id=${queryParams.event_id}` : ''}`);

            return;
        }

        setShowMergeModal(true);
    }, [isAuth]);

    const onCloseModal = useCallback(() => {
        setShowEditModal(false);
        setShowMergeModal(false);
    }, []);

    const elPersonEdit = useMemo(() => {
        if (showEditModal && person) {
            return (
                <PersonEdit
                    onCloseModal={onCloseModal}
                    person={person}
                />
            );
        }

        if (showMergeModal && person) {
            return (
                <PersonMerge
                    onCloseModal={onCloseModal}
                    person={person}
                />
            );
        }
    }, [showEditModal, showMergeModal, JSON.stringify(person)]);

    return (
            <div key={props.item.id} className={cn('list-item')}>
                {elPersonEdit}
                <div className={cn('list-item__wrapper__container', false && 'list-item__wrapper__container-active')}>
                    <div className={cn('list-item__wrapper')}>
                        <a target="_blank" href={`/persons/${props.item.id}`} title="Перейти на страницу персоны">
                            <Avatar imgSrc={props.item.photo} size={48} />
                        </a>
                        <div className={cn('list-item__content')}>
                            {(props.item.first_name && props.item.last_name) ? props.item.full_name : props.item.id}
                            {person && (
                                <PersonControlIcons
                                    className={cn('list-item__search-partner-info')}
                                    person={person}
                                    onClickEditPerson={onClickEditPerson}
                                    onClickMergePerson={onClickMergePerson}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
    );
};
