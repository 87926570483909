import React, { useMemo } from 'react';
import { faEdit, faPeopleArrows, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { useClassnames } from 'hook/use-classnames';
import style from '../index.pcss';
import { Person } from 'src/api/persons/types';

interface IProps {
    className?: string;
    person: Person | null;
    onClickEditPerson?(): void;
    onClickMergePerson(): void;
    isHideInfo?: boolean;
}
const PersonControlIcons = ({
    className,
    person,
    onClickEditPerson,
    onClickMergePerson,
    isHideInfo
}: IProps) => {
    const cn = useClassnames(style);

    const elPersonInfo = useMemo(() => {
        return (
            <div className={cn('person-info__tooltip')}>
                <span>
                    {person?.first_name} {person?.last_name}
                </span>
                <div className={cn('person-info__tooltip-table')}>
                    <div className={cn('person-info__tooltip-table-title')}>
                        <div>Полное имя: </div>
                        <div>Город: </div>
                        <div>Тренер: </div>
                    </div>
                    <div className={cn('person-info__tooltip-table-content')}>
                        <div>{person?.first_name ? person.full_name : 'не указано'}</div>
                        <div>{person?.location?.name ? person.location.name : 'не указан'}</div>
                        <div>{person?.trainer_name ? person.trainer_name : 'не указан'}</div>
                    </div>
                </div>
            </div>
        );
    }, [person]);

    return (
        <div className={cn('person__name__icon', className && `${className}`)}>
            {onClickEditPerson && (
                <>
                    <ReactTooltip
                        id={`edit-person-tooltip-${person?.id}`}
                        place="left"
                        effect="solid"
                        border={true}
                        borderColor="#dee1e4"
                        backgroundColor="#fff"
                        className={cn('person-photo__sale-tooltip')}
                    >
                        Рассказать о персоне
                    </ReactTooltip>
                    <FontAwesomeIcon
                        className={cn('person__name__icon-default')}
                        data-tip={true}
                        data-type="light"
                        data-for={`edit-person-tooltip-${person?.id}`}
                        icon={faEdit}
                        onClick={onClickEditPerson}
                    />
                </>
            )}
            <ReactTooltip
                id={`merge-person-tooltip-${person?.id}`}
                place="left"
                effect="solid"
                border={true}
                borderColor="#dee1e4"
                backgroundColor="#fff"
                className={cn('person-photo__sale-tooltip')}
            >
                Объединить персону с похожей
            </ReactTooltip>
            <FontAwesomeIcon
                className={cn('person__name__icon-default')}
                data-tip={true}
                data-type="light"
                data-for={`merge-person-tooltip-${person?.id}`}
                icon={faPeopleArrows}
                onClick={onClickMergePerson}
            />
            {!isHideInfo && (
                <>
                    <ReactTooltip
                        id={`person-info-tooltip-${person?.id}`}
                        place="left"
                        effect="solid"
                        border={true}
                        borderColor="#dee1e4"
                        backgroundColor="#fff"
                        className={cn('person-photo__sale-tooltip')}
                    >
                        {elPersonInfo}
                    </ReactTooltip>
                    <FontAwesomeIcon
                        className={cn('person__name__icon-question')}
                        data-tip={true}
                        data-type="light"
                        data-for={`person-info-tooltip-${person?.id}`}
                        icon={faQuestionCircle}
                    />
                </>
            )}
        </div>
    );
};

export default PersonControlIcons;
