import React, { ImgHTMLAttributes, RefAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import UI from 'component/ui';
import Loader from 'component/loader';
import Button from 'component/button';
import ErrorBlock from 'component/error';
import { getPhotosArchive } from 'component/api/photo';
import style from './index.pcss';
import { clearCart } from 'store/reducers/cart/actions';
import api from 'src/api';
import { OrderRetrieve } from 'src/api/order/types';
import cropImage from 'component/helper/crop-image';
import qaAttributes from 'component/helper/qa-attributes';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

const History = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, tokenDownloadAll] = useCancelTokens(2);
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const { id, status }: { id?: string, status?: 'success' | 'fail' } = useParams();

    const [error, setError] = useState<string | null>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [pendingArchive, setPendingArchive] = useState<boolean>(false);
    const [archiveUrl, setArchiveUrl] = useState<string | null>(null);
    const [order, setOrder] = useState<OrderRetrieve>();
    const [paymentStatus, setPaymentStatus] = useState<string>('');

    const _request = () => {
        setPending(true);

        api.order.getOrderItem(Number(id))
            .then((resp) => {
                setPaymentStatus(resp.data.payment_status);
                setOrder(resp.data);
                setPending(false);
            })
            .catch((err) => {
                console.error(err);
                setError(err.message || t('route.order-info.error'));
                setPending(false);
            });
    };

    const _requestArchive = useCallback(() => {
        if(order?.items.length) {
            setPendingArchive(true);

            getPhotosArchive({
                params: {
                    photo_ids: order.items.map((orderItem) => orderItem.photo?.id)
                },
                cancelToken: tokenDownloadAll.new()
            })
                .then((resp) => {
                    setArchiveUrl(resp.url);
                    setPendingArchive(false);
                })
                .catch((err) => {
                    if(!axios.isCancel(err)) {
                        console.error(err);
                        setPendingArchive(false);
                    }
                });
        }
    }, [JSON.stringify(order)]);

    useEffect(() => {
        _request();
    }, [id]);

    useEffect(() => {
        if(order?.items.length) {
            _requestArchive();
        }
    }, [JSON.stringify(order)]);

    useEffect(() => {
        return () => {
            token.remove();
            tokenDownloadAll.remove();
        };
    }, []);

    useEffect(() => {
        if(status === 'success') {
            dispatch(clearCart());
            history.replace(`/history/${id}`);
        }
    }, [status]);

    const elBlockHeader = useMemo(() => {
        if(id) {
            return <h2 className={cn('order-info__block-header')}>{t('route.order-info.block-header', { id })}</h2>;
        }
    }, [id]);

    const elLoader = useMemo(() => {
        if(pending) {
            return <Loader className={cn('order-info__loader')} />;
        }
    }, [pending]);

    const elOrder = useMemo(() => {
        if(order?.items.length) {
            return (
                <div className={cn('order-info__photo-list')}>
                    {order.items.map((item) => {
                        const author = item.photo.photographer.first_name && item.photo.photographer.last_name
                            ? `${item.photo?.photographer?.first_name} ${item.photo.photographer.last_name}`
                            : item.photo.photographer.first_name ? item.photo.photographer.first_name
                            : t('route.order-info.item.author-empty');
                        const event = item.photo?.event?.name || t('route.order-info.item.event-empty');
                        const price = Number(item.price);

                        const params: RefAttributes<HTMLImageElement> & ImgHTMLAttributes<HTMLImageElement> = {
                            ...qaAttributes('carousel:thumbs:image'),
                            src      : cropImage(item.photo.photo_url, '520x'),
                            alt      : item.photo.id.toString(),
                            className: cn('order-info__item-image')
                        };

                        return (
                            <div className={cn('order-info__list-item')} key={item.photo.id}>
                                <img {...params} />
                                <div className={cn('order-info__item-content')}>
                                    <div className={cn('order-info__item-block')}>
                                        <p className={cn('order-info__item-text')}>{event}</p>
                                        <p className={cn('order-info__item-text-sub')}>{t('route.order-info.item.author', { name: author })}</p>
                                        <a
                                            className={cn('order-info__item-text-sub', 'order-info__item-text-sub_remove')}
                                            href={item.photo.photo_url}
                                            download={true}
                                        >
                                            {t('route.order-info.item.download')}
                                        </a>
                                    </div>
                                    <span className={cn('order-info__item-price')}>{t('route.order-info.item.price', { count: price })}</span>
                                </div>
                            </div>
                        );
                    })}
                    {elError}
                </div>
            );
        }
    }, [JSON.stringify(order)]);

    const elStatus = useMemo(() => {
        let text = '';

        switch(paymentStatus) {
            case 'PAYED': {
                text = t('route.order-info.status.payed');
                break;
            }

            case 'CANCELLED': {
                text = t('route.order-info.status.cancelled');
                break;
            }

            case 'ERROR': {
                text = t('route.order-info.status.error');
                break;
            }

            case 'INITED': {
                text = t('route.order-info.status.inited');
                break;
            }

            case 'INVOICED': {
                text = t('route.order-info.status.invoiced');
                break;
            }

            case 'PLACED': {
                text = t('route.order-info.status.placed');
                break;
            }
        }

        return <span className={cn('order-info__status')}>{text}</span>;
    }, [paymentStatus]);

    const elContent = useMemo(() => {
        return (
            <div className={cn('order-info__content-box')}>
                <div className={cn('order-info__header-block')}>
                    {elBlockHeader}
                    {elStatus}
                </div>
                {elOrder}
            </div>
        );
    }, [JSON.stringify(order), paymentStatus]);

    const elError = useMemo(() => {
        if(error) {
            return <ErrorBlock className={cn('order-info__error')}>{error}</ErrorBlock>;
        }
    }, [error]);

    if (!isAuth) {
        history.push('/login');
    }

    return (
        <UI.Main className={cn('order-info')}>
            <h1 className={cn('order-info__header')}>{t('route.order-info.header')}</h1>
            <div className={cn('order-info__grid')}>
                <UI.Box padding={true} className={cn('order-info__content')}>
                    {elContent}
                    {elError}
                </UI.Box>
                <UI.Box padding={true} className={cn('order-info__sidebar')}>
                    <Button
                        className={cn('order-info__button')}
                        disabled={pendingArchive || !archiveUrl || paymentStatus !== 'PAYED'}
                        isLoading={pendingArchive}
                        href={archiveUrl || undefined}
                        download={archiveUrl || undefined}
                    >
                        {t('route.order-info.download')}
                    </Button>
                    <Button
                        to="/history"
                        isSecondary={true}
                        className={cn('order-info__button', 'order-info__button_secondary')}
                    >
                        {t('route.order-info.history')}
                    </Button>
                </UI.Box>
            </div>
        </UI.Main>
    );
};

export default History;
