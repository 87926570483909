import React, {
    FC,
    MouseEvent,
    useMemo,
    useCallback,
    useEffect,
    useReducer,
    useRef,
    ReactNode,
    RefAttributes,
    ImgHTMLAttributes,
    useState,
    Fragment
} from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { useSwipeable } from 'react-swipeable';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Magnifier from 'react-magnifier';
import IconClose from 'component/icon/close';
import IconArrowLeft from 'component/icon/arrow-left';
import Loader from 'component/loader';
import Button from 'component/button';

import useClassnames from 'hook/use-classnames';
import qaAttributes from 'component/helper/qa-attributes';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import { key as cartKey } from 'store/reducers/cart/reducer';
import { key as keyUser } from 'store/reducers/user/reducer';
import { CartItemsStore, IStore as IStoreCart } from 'store/reducers/cart/types/reducer';

import { CarouselPhoto, IProps } from './types';
import { selected, prev, next, setTotal } from './actions';
import reducer from './reducer';
import style from './index.pcss';
import { Photo, PhotoItem } from 'src/api/photos/types';
import api from 'src/api';
import { addCart } from 'store/reducers/cart/actions';
import { CartItem } from 'src/api/cart/types';

const bytesToMegaBytes = (bytes: number) => (bytes / (1024 * 1024)).toFixed(2);

const EventCarousel: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const $currentThumb = useRef<HTMLImageElement>(null);
    const cart = useSelector<IStore, IStoreCart>((storeApp) => storeApp[cartKey]);
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const userPersonalPhotoId = useSelector<IStore, number | undefined>((storeApp) => storeApp[keyUser].personal_photo?.id);
    const userId = useSelector<IStore, number | undefined>((storeApp) => storeApp[keyUser].id);

    const dispatchStore = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [children, setChildren] = useState<Array<CarouselPhoto>>(props.children || []);
    const [store, dispatch] = useReducer(reducer, {
        current: props.selected || 0,
        total  : props.children ? props.children.length : 0
    });
    const [currentPhoto, setCurrentPhoto] = useState<PhotoItem>();
    const [cartItemsListPage, setCartItemsListPage] = useState<number>(1);
    const [isCartItemsListLoading, setIsCartItemsListLoading] = useState<boolean>(false);
    const [isCartItemsListLoadMore, setIsCartItemsListLoadMore] = useState<boolean>(false);
    const [cartItemsList, setCartItemsList] = useState<Array<CartItem>>([]);

    useEffect(() => {
        if (isCartItemsListLoading || isCartItemsListLoadMore) {
            api.cart.getCartItemsList({
                pageNumber: cartItemsListPage
            })
            .then((resp) => {
                const filteredList: CartItemsStore = {
                    count: 0,
                    items: []
                };

                setCartItemsList((previous) => isCartItemsListLoadMore ?
                    [...previous, ...resp.data.results] : resp.data.results);
                if (!resp.data.next) {
                    const newList = isCartItemsListLoadMore ? [...cartItemsList, ...resp.data.results].map((item) => filteredList.items.push(item))
                        : resp.data.results.map((item) => filteredList.items.push(item));
                    filteredList.count = resp.data.count;
                    dispatchStore(addCart(filteredList));
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);
                } else {
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);

                    setCartItemsListPage((previous) => previous + 1);
                    setIsCartItemsListLoadMore(true);
                }
            });
        }
    }, [isCartItemsListLoading, isCartItemsListLoadMore]);

    useEffect(() => {
        setChildren(props.children || []);
    }, [JSON.stringify(props.children)]);

    useEffect(() => {
        if(props.children && props.onSelectSlide) {
            const item = props.children[store.current];

            props.onSelectSlide(item);
        }
    }, [store.current, JSON.stringify(props.children), props.onSelectSlide]);

    const isMobile = useSelector<IStore, boolean>((state) => state[keyDeviceInfo].mobile);

    const onClose = useMemo(() => (e?: MouseEvent): void => {
        e?.preventDefault();

        if(props.onClose) {
            props.onClose();
        }
    }, [props.onClose]);

    const scrollThumb = useCallback((options: ScrollIntoViewOptions) => {
        if($currentThumb.current) {
            $currentThumb.current.scrollIntoView(options);
        }
    }, []);

    const slide = {
        prev: (): void => {
            if(Array.isArray(children) && children.length > 1) {
                if(!loading) {
                    setLoading(true);
                }

                dispatch(prev());

                if(!props.noScroll) {
                    scrollThumb({
                        block: 'start'
                    });
                }
            }
        },
        next: (): void => {
            if(Array.isArray(children) && children.length > 1) {
                if(!loading) {
                    setLoading(true);
                }

                dispatch(next());

                if(!props.noScroll) {
                    scrollThumb({
                        block: 'start'
                    });
                }
            }
        }
    };

    const handlersSwipe = useSwipeable({
        onSwipedLeft : slide.next,
        onSwipedRight: slide.prev
    });

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'Escape':
                    onClose();
                    break;
                case 'ArrowLeft':
                    slide.prev();
                    break;
                case 'ArrowRight':
                    slide.next();
                    break;
            }
        };

        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, []);

    useEffect(() => {
        if(props.onEndOfSlides && children && children.length > 1 && store.current >= children.length - 2) {
            props.onEndOfSlides();
        }
    }, [store.current]);

    useEffect(() => {
        if(children) {
            dispatch(setTotal(children.length));
        }
    }, [JSON.stringify(children)]);

    const onLoad = (): void => {
        if(loading) {
            setLoading(false);
        }
    };

    const onClickAddToCart = (id: number) => () => {
        api.cart.createCartItem('photo', id)
            .then(() => {
                setCartItemsListPage(1);
                setIsCartItemsListLoading(true);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const onClickRemoveFromCart = (id: number) => () => {
        const cartItemId = cart.items?.find((item) => item.photo.id === currentPhoto?.id)?.id;

        if (currentPhoto && cartItemId) {
            api.cart.destroyCartItem(cartItemId)
               .then(() => {
                    setCartItemsListPage(1);
                    setIsCartItemsListLoading(true);
                })
               .catch((err) => {
                    console.error(err);
                });
        }
    };

    useEffect(() => {
        api.photos.getPhotoItem(children[store.current].id)
            .then((resp) => {
                setCurrentPhoto(resp.data);
            });
    }, [store.current]);

    const elCurrent = useMemo(() => {
        if(Array.isArray(children) && children[store.current]) {
            const item = children[store.current];

            return (
                <Fragment>
                    <div onLoad={onLoad} className={cn('carousel__current')}>
                        <Magnifier
                            height={'100%'}
                            max-height={'60vh'}
                            width={'100%'}
                            src={item.photo_url}
                            zoomImgSrc={item.photo_url}
                            {...qaAttributes('carousel:current:image')}
                        />
                    </div>
                </Fragment>
            );
        }
    }, [store.current, JSON.stringify(cart), props.disableButtons, props.photoOwner, isAuth, currentPhoto]);

    const elPrice = () => {
        const item = children[store.current];
        if (userPersonalPhotoId !== item.id && item.price) {
            if (!props.disableButtons) {
                return (
                    <>
                        <div className={cn('carousel__info-header')}>Понравилось фото?</div>
                        <div className={cn('carousel__info-text')}>
                            <p>
                                Купите фотографию в хорошем качестве за
                                <span className={cn('carousel__info-price')}><strong>{item.price}</strong> руб.</span>
                            </p>
                        </div>
                    </>
                );
            } else {
                return (
                    <div>
                        <div className={cn('carousel__info-text')}>
                            <p>
                                Цена фотографии
                                <span className={cn('carousel__info-price')}>
                                    <strong> {item.price} </strong> руб.
                                </span>
                            </p>
                        </div>
                    </div>
                );
            }
        }
    };

    const elInfo = useMemo(() => {
        if(Array.isArray(children) && children[store.current]) {
            const item = children[store.current];
            const alreadyInCart = cart.items?.find((cartKeyItem) => cartKeyItem.photo.id === item.id);
            const title = currentPhoto?.photographer.id === userId ? 'Нельзя купить собственное фото' : '';
            const resolution = currentPhoto?.original_file_width ? `Разрешение: ${currentPhoto?.original_file_width} x ${currentPhoto?.original_file_height}` : '';
            const size = currentPhoto?.original_file_size ? `Размер: ${bytesToMegaBytes(currentPhoto.original_file_size)} Мб` : '';
            const mainButtonProps = {
                onClick    : alreadyInCart ? onClickRemoveFromCart(item.id) : onClickAddToCart(item.id),
                isSecondary: !!alreadyInCart,
                className  : cn('carousel__menu-button', 'carousel__info-button'),
                disabled   : !item.price || currentPhoto?.photographer.id === userId,
                title,
                children   : alreadyInCart ? 'Удалить из корзины' : 'Добавить в корзину'
            };

            return (
                <div className={cn('carousel__info', {'carousel__info-modal': props.isModal})}>
                    <div className={cn('carousel__info-header')}>О снимке</div>
                    <div className={cn('carousel__info-text')}>
                        <p>Дата: {moment(item.upload_at).format('ll')}</p>
                        <p>{resolution}</p>
                        <p>{size}</p>
                    </div>
                    {elPrice()}
                    {!props.disableButtons && (
                        <div>
                            <Button {...mainButtonProps} />
                        </div>
                    )}
                </div>
            );
        }
    }, [store.current, JSON.stringify(cart), props.disableButtons, props.photoOwner, isAuth, currentPhoto]);

    const elHeader = useMemo(() => {
        const item = children[store.current];

        return (
            <div className={cn('carousel__header', {'carousel__header-modal': props.isModal})}>
                {item?.event?.name}
            </div>
        );
    }, [store.current, props.isModal]);

    const elZoom = useMemo(() => {
        const item = children[store.current];

        return (
            <div className={cn('carousel__main-zoom')} />
        );
    }, [store.current]);

    const onClickThumb = (index: number) => (e: MouseEvent): void => {
        e.preventDefault();

        if(index !== store.current) {
            dispatch(selected(index));

            if(props.onSelectSlide && children) {
                const item = children[index] || '';

                props.onSelectSlide(item);
            }
        }
    };

    const elThumbs = (): ReactNode => {
        if(Array.isArray(children) && children.length > 1 && !isMobile) {
            const childrenToRender = children;

            return (
                <div
                    className={cn('carousel__thumbs', {
                        'carousel__thumbs_modal': props.isModal
                    })}
                    {...qaAttributes('carousel:thumbs')}
                >
                    <div className={cn('carousel__thumbs-container')}>
                        {children.map((item, index) => {
                            const params: RefAttributes<HTMLImageElement> & ImgHTMLAttributes<HTMLImageElement> = {
                                ...qaAttributes('carousel:thumbs:image'),
                                src      : item.photo_url,
                                alt      : t('components.carousel.thumb', { index }),
                                className: cn('carousel__thumb', {
                                    'carousel__thumb_active': index === store.current
                                })
                            };

                            if(index === store.current) {
                                // params.ref = $currentThumb;
                            } else {
                                params.onClick = onClickThumb(index);
                            }

                            return <img key={index} {...params} />;
                        })}
                    </div>
                </div>
            );
        }
    };

    const onClickStop = (e: MouseEvent): void => {
        e?.stopPropagation();
    };

    const elControl = useCallback<(direction: 'prev' | 'next') => ReactNode>((direction) => {
        if(Array.isArray(children) && children.length > 1) {
            const className = cn('carousel__control-wrapper', `carousel__control-wrapper_${direction}`, {
                'carousel__control-wrapper_modal'         : props.isModal,
                [`carousel__control-wrapper_modal-${direction}`]: props.isModal
            });

            return (
                <div onClick={slide[direction]} className={className}>
                    <IconArrowLeft
                        width={20}
                        height={20}
                        className={cn('carousel__control', `carousel__control_${direction}`)}
                        {...qaAttributes(`carousel:control:${direction}`)}
                    />
                </div>
            );
        }
    }, []);

    const elLoading = useMemo((): ReactNode => {
        if(loading) {
            return <Loader className={cn('carousel__loader')} {...qaAttributes('carousel:loader')} />;
        }
    }, [loading]);

    const elCloseIcon = useMemo(() => {
        if(props.isModal) {
            return (
                <IconClose
                    width={20}
                    height={20}
                    onClick={onClose}
                    className={cn('carousel__close')}
                />
            );
        }
    }, [props.isModal]);

    const elCarousel = () => {
        return (
            <div
                className={cn('carousel', { 'carousel_modal': props.isModal })}
                onClick={onClose}
                {...qaAttributes(props['data-qa'] ? `carousel:${props['data-qa']}` : 'carousel')}
            >
                <div
                    className={cn('carousel__container', {
                        'carousel__container_modal': props.isModal
                    })}
                    onClick={onClickStop}
                >
                    {elCloseIcon}

                    <div className={cn('carousel__wrapper', {'carousel__wrapper-modal': props.isModal})}>
                        {elHeader}
                        <div
                            className={cn('carousel__main', {
                                'carousel__main_single': Array.isArray(children) && children.length === 1,
                                'carousel__main_modal' : props.isModal
                            })}
                            data-indicator={props.disableCountIndicator ? null : `${store.current + 1}/${store.total}`}
                            {...handlersSwipe}
                            {...qaAttributes('carousel:main')}
                        >

                            {elControl('prev')}

                            {elLoading}
                            {elCurrent}

                            {elControl('next')}
                        </div>
                        {/*{elZoom}*/}
                        {elThumbs()}
                    </div>
                    {elInfo}
                </div>
            </div>
        );
    };

    if(props.isModal) {
        return createPortal((elCarousel()), document.body);
    }

    return elCarousel();
};

// tslint:disable-next-line:max-file-line-count
export default EventCarousel;
