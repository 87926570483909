import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import { useClassnames } from 'hook/use-classnames';

import { IProps } from './types';
import style from './index.pcss';

const CarouselItem: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    return (
        <div
            className={cn('item')}
        >
            <div
                onClick={props.onClick}
                className={cn('item__avatar', {
                    'item__avatar--highlighted': props.item.is_partner_profile,
                    'item-selected': props.isSelected
                })}
            >
                <ReactTooltip
                    id={`carousel-item-person-${props.item.id}`}
                    effect="solid"
                    border={true}
                    borderColor="#dee1e4"
                    backgroundColor="#fff"
                >
                    <div>
                        <p>{props.item.name}</p>
                    </div>
                </ReactTooltip>
                <img data-type="light" data-tip={true} data-for={`carousel-item-person-${props.item.id}`} src={props.item.photo_url} />
            </div>
            {
                props.isName && (
                    <div className={cn('item__name')}>
                        <a href={`/persons/${props.item.id}`}>{props.item.name}</a>
                    </div>
                )
            }
        </div>
    );
};

CarouselItem.defaultProps = {
    isSelected: false,
    isName: false
};

export default CarouselItem;
