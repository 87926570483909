import React, { useEffect, useMemo, useState } from 'react';
import useIntersect from 'hook/use-intersect';
import { useTranslation } from 'react-i18next';
import { useClassnames } from 'hook/use-classnames';
import { Link } from 'react-router-dom';
import { OrderList } from 'src/api/order/types';
import UI from 'component/ui';
import Loader from 'component/loader';
import style from './index.pcss';
import moment from 'moment';
import api from 'src/api';
import { Page } from 'src/api/base';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

const History = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);

    const [error, setError] = useState<string | null>(null);
    const [pending, setPending] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [list, setList] = useState<Array<OrderList>>([]);
    const [isOrderListPending, setIsOrderListPending] = useState<boolean>(false);
    const [isOrderListNext, setIsOrderListNext] = useState<boolean>(true);
    const [isOrderListMerge, setIsOrderListMerge] = useState<boolean>(false);
    const [orderListPage, setOrderListPage] = useState<number>(1);

    useEffect(() => {
        if (!isOrderListPending) {
            return;
        }

        const page: Page = {
            pageNumber: orderListPage,
            pageSize: 10
        };

        const filter = {
            payment_status: 'PAYED'
        };

        api.order.getOrderList(page, filter)
            .then((resp) => {
                setTotal(resp.data.count);
                const newList = resp.data.results.filter((item) => item.payment_status === 'PAYED');

                setList(
                    isOrderListMerge
                    ? [...list, ...newList] : newList);

                setIsOrderListNext(!!resp.data.next);
                setIsOrderListPending(false);
                pending && setPending(false);
            })
            .catch((err) => {
                console.error(err);
                // setError(err.message || t('route.history.error'));
                setIsOrderListNext(false);
                setIsOrderListPending(false);
                pending && setPending(false);
            });
    }, [isOrderListPending]);

    useEffect(() => {
        setIsOrderListNext(true);
        setIsOrderListPending(true);
    }, []);

    const elOrderList = useMemo(() => {
        if (list.length) {
            return (
                <div className={cn('history__order-list')}>
                    {list.map((item) => {
                        return (
                            <Link to={`/history/${item.id}`} className={cn('history__list-item')} key={item.id}>
                                <span className={cn('history__item-text')}>{t('route.history.orders-header.order-number', { id: item.id })}</span>
                                <span className={cn('history__item-text')}>{t('route.history.orders-header.order-date', { date: moment(item.paid_at).format('LL') })}</span>
                                <span className={cn('history__item-text')}>
                                    <span>{t('route.history.orders-header.photo-count', { count: item.items.length })}<br /></span>
                                </span>
                                <span className={cn('history__item-text')}>{t('route.history.orders-header.order-price', { count: item.amount })}</span>
                            </Link>
                        );
                    })}
                    {isOrderListPending && <Loader />}
                    <div className={cn('history__item-count')}>{t('route.history.count', { count: list.length, total })}</div>
                </div>
            );
        }

        return (
            <div className={cn('history__order-list')}>
                <h3>История покупок пуста</h3>
            </div>
        );
    }, [JSON.stringify(list), total, isOrderListPending]);

    // const elError = useMemo(() => {
    //     if(error) {
    //         return <ErrorBlock className={cn('history__error')}>{error}</ErrorBlock>;
    //     }
    // }, [error]);

    const $LoadMoreOrders = useIntersect((entry) => {
        if(entry.isIntersecting && isOrderListNext && !isOrderListPending) {
            setIsOrderListMerge(true);
            setOrderListPage((prev) => prev + 1);
            setIsOrderListPending(true);
        }
    }, {
        rootMargin: '200px 0px'
    });

    const elLoadMoreOrders = () => {
        if (!isOrderListPending && list.length && isOrderListNext) {
            if (total > list.length) {
                return <Loader ref={$LoadMoreOrders} />;
            }
        }
    };

    if (!isAuth) {
        history.push('/login');
    }

    if (pending) {
        return <Loader className={cn('history__loader')} />;
    }

    return (
        <UI.Main className={cn('history')}>
            <UI.PageHeader className={cn('history__header')} text={t('route.history.header')} />
            <UI.Box padding={true} className={cn('history__content')}>
                {elOrderList}
                {elLoadMoreOrders()}
                {/* {elError} */}
            </UI.Box>
        </UI.Main>
    );
};

export default History;
