import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import history from 'component/core/history';
import Registration from 'route/registration';
import Login from 'route/login';
import ConfirmRegistration from 'route/registration/confirm';

// Pages
import Album from 'route/album';
import Banners from 'route/banners';
import Home from 'route/home';
import NotFound from 'route/not-found';
import Dashboard from 'route/dashboard';
import Upload from 'route/upload';
import Photos from 'route/dashboard/photos';
import PhotosDate from 'route/dashboard/photos/date';
import Persons from 'route/persons';
import Person from 'route/person';
import Cart from 'route/cart';
import Success from 'route/success';
import Search from 'route/search';
// import Events from 'route/events';
import Event from 'route/event';
import Photo from 'route/photo';
import Forgot from 'route/password/forgot';
import Reset from 'route/password/reset';
import Reports from 'route/reports';
import Subscriptions from 'route/subscriptions';
import PurchaseHistory from 'route/history';
import PhotoSearch from 'route/search-photo';
import OrderInfo from 'route/order-info';
import Photographer from 'route/photographer';
import About from 'route/about';
import Condition from 'route/condition';
import Invite from 'route/invite';
import InvitedAlbums from 'route/invited-albums';
import InvitedAlbum from 'route/invited-album';

import Layout from 'component/layout';
import PersonPhotos from 'route/person/photos';
import PersonPhoto from 'route/person/photo';
import FAQ from 'route/faq';
import YaAuth from 'route/ya-auth';
import PremiumAccount from 'route/premium-account';
import EventPhoto from './event/photo';
import UploadVideo from './upload-video';

const Routes = () => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <Layout>
            <Helmet
                title={t('helmet.title.global')}
                meta={[{
                    name   : 'description',
                    content: t('helmet.description')
                }, {
                    name   : 'keywords',
                    content: t('helmet.keywords')
                }, {
                    name   : 'document-state',
                    content: 'dynamic'
                }, {
                    name   : 'robots',
                    content: 'all'
                }]}
            />

            <Switch location={location}>
                <Route exact={true} path="/" component={Home} />
                <Route exact={true} path="/faq" component={FAQ} />
                <Route exact={true} path="/about" component={About} />
                <Route exact={true} path="/albums/:id/" component={Album} />
                <Route exact={true} path="/condition" component={Condition} />
                <Route exact={true} path={['/upload-photo', '/upload', '/files', '/detect', '/manage']} component={Upload} />
                <Route exact={true} path="/upload-video" component={UploadVideo} />
                <Route exact={true} path="/registration" component={Registration} />
                <Route exact={true} path="/reports" component={Reports} />
                <Route exact={true} path="/confirm/:token" component={ConfirmRegistration} />
                <Route exact={true} path="/login" component={Login} />
                <Route exact={true} path="/password/reset" component={Forgot} />
                <Route exact={true} path="/reset-password/:uid/:token" component={Reset} />
                <Route exact={true} path="/dashboard" component={Dashboard} />
                <Route exact={true} path="/dashboard/banners" component={Banners} />
                <Route exact={true} path="/dashboard/photos" component={Photos} />
                <Route exact={true} path="/dashboard/photos/:date" component={PhotosDate} />
                <Route exact={true} path="/persons/photos/" component={PersonPhotos} />
                <Route exact={true} path="/persons" component={Persons} />
                <Route exact={true} path="/persons/:id" component={Person} />
                <Route exact={true} path="/persons/photos/:id" component={PersonPhoto} />
                <Route exact={true} path="/subscriptions" component={Subscriptions} />
                <Route exact={true} path="/photographer/:id" component={Photographer} />
                <Route exact={true} path="/cart" component={Cart} />
                <Route exact={true} path="/payment/success" component={Success} />
                <Route exact={true} path="/search" component={Search} />
                <Route exact={true} path="/search-photo" component={PhotoSearch} />
                <Route exact={true} path="/events/:id/" component={Event} />
                <Route exact={true} path="/events/:id/:photoId?" component={EventPhoto} />
                <Route exact={true} path="/history" component={PurchaseHistory} />
                <Route exact={true} path="/history/:id?/:status(success|fail)?" component={OrderInfo} />
                <Route exact={true} path="/invite/:token" component={Invite} />
                <Route exact={true} path="/invited-albums" component={InvitedAlbums} />
                <Route exact={true} path="/invited-albums/:id" component={InvitedAlbum} />
                <Route exact={true} path="/invited-albums/:albumId/:photoId" component={Photo} />
                <Route exact={true} path="/auth-ya/suggest/token" component={YaAuth} />
                <Route exact={true} path="/premium-account" component={PremiumAccount} />
                <Route exact={true} path="/404" component={NotFound} />
                <Redirect to="/404" from="*" />
            </Switch>
        </Layout>
    );
};

export default (): ReactElement<Router> => (
    <Router history={history}>
        <Route component={Routes} />
    </Router>
);
