import { createAction } from '@reduxjs/toolkit';
import { PremiumAccountRetrieve } from 'src/api/accounts/types';

export const setPremium = createAction<Partial<PremiumAccountRetrieve>>(
    '@@premium/SET'
);

export const resetPremium = createAction(
    '@@premium/RESET'
);

export default {
    setPremium,
    resetPremium
};
