import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useCancelTokens } from 'component/core/cancel-token';
import Button from 'component/button';
import { IStore } from 'store/reducers/types/reducers';
import { getConfigurationCost } from 'component/api/configuration';
import { key as keyUser } from 'store/reducers/user/reducer';
import { Data as ConfigurationCostData } from 'component/api/types/api/configuration/configuration/cost/get/code-200';
import Modal from 'component/modal';

export const useRespondAd = () => {
    const [tokenSubscription] = useCancelTokens(1);
    const dispatch = useDispatch();

    const isFindPartnerSubscription = useSelector<IStore, boolean | null | undefined>((store) => store[keyUser].find_partner_subscription?.is_active);
    const [subscriptionCost, setSubscriptionCost] = useState<ConfigurationCostData | null>(null);
    const [messageSubscription, setMessageSubscription] = useState<boolean>(false);
    const [errorSubscription, setErrorSubscription] = useState<boolean>(false);

    useEffect(() => {
        getConfigurationCost({
            cancelToken: tokenSubscription.new()
        })
            .then((payload) => {
                setSubscriptionCost(payload);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                    setErrorSubscription(err.message);
                }
            });

        return () => {
            tokenSubscription.remove();
        };
    }, []);

    const onClickRespondCheck = (): boolean => {
        setErrorSubscription(false);
        setMessageSubscription(false);

        if (!isFindPartnerSubscription) {
            if (!subscriptionCost?.find_partner_month_subscription) {
                setErrorSubscription(true);

                return false;
            }

            const purchaseSubscription = {
                id: -1,
                price: subscriptionCost.find_partner_month_subscription
            };

            // TODO: исправить с новой логикой корзины
            // dispatch(addItem(purchaseSubscription));
            setMessageSubscription(true);

            return false;
        }

        return true;
    };

    const onClickCloseMessage = (): void => {
        setMessageSubscription(false);
        setErrorSubscription(false);
    };

    const elMessagesSubscription = useMemo(() => {
        if (errorSubscription) {
            return (
                <Modal onClickClose={onClickCloseMessage}>
                    <div style={{padding: '16px 32px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        На данный момент покупка подписки на отклик партнера не возможна!
                    </div>
                </Modal>
            );
        }

        if (messageSubscription) {
            return (
                <Modal onClickClose={onClickCloseMessage}>
                    <div style={{padding: '16px 32px', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        Необходимо оплатить подписку на публикацию анкеты, перейдите в корзину для оплаты подписки!
                        <Link to={'/cart'} target="_blank" style={{marginTop: '16px'}}>
                            <Button>
                                Перейти в корзину для оплаты
                            </Button>
                        </Link>
                    </div>
                </Modal>
            );
        }
    }, [messageSubscription, errorSubscription]);

    return [onClickRespondCheck, elMessagesSubscription] as const;
};
