import React, { FC, useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import uniqBy from 'lodash.uniqby';

import { useCancelToken } from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Button from 'component/button';
import InputFile from 'component/form/input-file';
import Form, { useRegistry } from 'component/form';
import PhotoList from 'component/photo-list';
import sortPhotoArray, { ISortedMap } from 'component/helper/sort-photos-list';

import { stringify, parse } from 'query-string';
import { bulkEditPhoto } from 'component/api/photo';
import { Data as CreateData } from 'component/api/types/api/photo/photo/create/post/code-200';

import style from './style.pcss';
import SidebarActions from 'component/sidebar-actions';
import { ISelectedMap } from 'route/dashboard/photos/types';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import InputVideo from 'component/form/input-video';
import api from 'src/api';
import { Page } from 'src/api/base';
import { GetVideoListData, VideoListItem } from 'src/api/videos/types';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import debounce from 'lodash.debounce';
import { AlbumItem } from 'src/api/albums/types';
import Modal from 'component/modal';
import { UploadingInfo } from 'component/form/input-file/types';
import { useAlert } from 'component/alert/provider';
import { normalizeObject } from 'component/helper/normalize-object';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const UploadVideo: FC = () => {
    const cn = useClassnames(style);
    const { show, hide } = useAlert();
    const { t } = useTranslation();
    const tokenEdit = useCancelToken();
    const registry = useRegistry();
    const history = useHistory();
    const [ queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());
    const match = useRouteMatch<{ component: string }>('/:component');
    const [images, setImages] = useState<Array<CreateData>>([]);
    const [videos, setVideos] = useState<Array<CreateData>>([]);
    const [photosMap, setPhotosMap] = useState<ISortedMap | null>(null);
    const [idsList, setIdsList] = useState<ISelectedMap>({});
    const [isContinueLoadingFiles, setIsContinueLoadingFiles] = useState<boolean>(false);
    const [uploadedVideoLink, setUploadedVideoLink] = useState<string>('');
    const [uploadedVideoId, setUploadedVideoId] = useState<number | null>();
    const [videoInfo, setVideoInfo] = useState<VideoListItem | null>();
    const [isLoadingVideoInfo, setIsLoadingVideoInfo] = useState<boolean>(false);
    const [albumInfo, setAlbumInfo] = useState<AlbumItem | null>();
    const refIds = useRef<ISelectedMap>(idsList);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState<boolean>(false);
    const [uploadingInfo, setUploadingInfo] = useState<UploadingInfo>();

    const saveUploadingVideoParams = (params: UploadingInfo) => {
        setUploadingInfo(params);
    };

    useEffect(() => {
        if (queryParams.album_id) {
            setIsLoadingVideoInfo(true);
            const page: Page = {
                pageNumber: 1
            };

            const data: GetVideoListData = {
                album_id: Number(queryParams.album_id)
            };

            api.albums.getAlbumRetrieve(queryParams.album_id)
                .then((resp) => {
                    setAlbumInfo(resp.data);
                })
                .catch(() => {
                    setAlbumInfo(null);
                });

            api.videos.getVideoList(page, data)
                .then((resp) => {
                    setVideoInfo(resp.data.results[0]);
                    setIsLoadingVideoInfo(false);

                    if (!resp.data.count) {
                        onVideoNotFound();
                    }
                })
                .catch(() => {
                    setIsLoadingVideoInfo(false);
                    onVideoNotFound();
                });
        }
    }, [queryParams.album_id]);

    if (!userId) {
        return <Redirect to={'/login'} />;
    }

    const onFilesLoaded = (newImages: Array<CreateData>): void => {
        setImages(newImages);
    };

    const onVideoFilesLoaded = (newImages: Array<CreateData>): void => {
        setVideos(newImages);
    };

    const onCancelLoading = (): void => {
        setImages([]);
        setIdsList([]);
    };

    const onCancelLoadingVideo = (): void => {
        setImages([]);
        setVideos([]);
        setIdsList([]);
    };

    const onVideoNotFound = debounce(useCallback(() => {
        const data = {
            album_id: queryParams.album_id
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, []), 300);

    useEffect(() => {
        if(!images) {
            history.replace('/upload-video');
        } else {
            setPhotosMap(sortPhotoArray(images));
        }
    }, [JSON.stringify(images)]);

    useEffect(() => {
        refIds.current = idsList;
    }, [JSON.stringify(idsList)]);

    const onChangeSelectedList = useCallback((index: number) => (ids: Array<number>) => {
        const newList = { ...refIds.current };

        newList[index] = ids;

        setIdsList(newList);
    }, [idsList]);

    const updPhotoInfo = useCallback((photo_data) => {
        const keys = Object.keys(refIds.current);

        const list = keys.reduce((accum: Array<number>, current: string) => {
            return accum.concat(refIds.current[Number(current)]);
        }, []);

        bulkEditPhoto({
            cancelToken: tokenEdit.new(),
            data       : {
                photo_ids: list,
                photo_data
            }
        })
            .then((payload) => {
                setImages(uniqBy([...payload.photo_list, ...images], 'id'));
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                }
            });
    }, [JSON.stringify(images)]);

    const elSidebarActions = useMemo(() => {
        const keys = Object.keys(idsList);

        const listLength = keys.reduce((accum: number, key: string) => {
            return accum + idsList[Number(key)].length;
        }, 0);

        return (
            <SidebarActions
                update={updPhotoInfo}
                checkedCount={listLength}
            />
        );
    }, [JSON.stringify(idsList), JSON.stringify(images)]);

    const elList = useMemo(() => {
        if(photosMap) {
            const keys = Object.keys(photosMap);

            return keys.map((key, index) => {
                return <PhotoList disableButtons={true} photoOwner={true} key={index} list={photosMap[key]} onChangeSelectedList={onChangeSelectedList(index)} />;
            });
        }
    }, [JSON.stringify(photosMap)]);

    const onClickDeleteVideo = () => {
        if (videoInfo || uploadedVideoId) {
            setIsConfirmDeleteModal(true);
        }
    };

    const onClickContinue = () => {
        setIsContinueLoadingFiles(true);
    };

    const onVideoLoaded = (video: string, video_id: number) => {
        setUploadedVideoLink(video);
        setUploadedVideoId(video_id);
    };

    const elVideoPlayer = useMemo(() => {
        return (
            <div className={cn('upload__content__preview')}>
            <video controls={true} src={videoInfo ? videoInfo.file.file : uploadedVideoLink} className={cn('upload__content__preview-item')}>
                Your browser does not support the video tag.
            </video>
            </div>
        );
    }, [uploadedVideoLink, videoInfo]);

    const onClickCloseModal = () => {
        setIsConfirmDeleteModal(false);
    };

    const onClickConfirmDelete = () => {
        if (uploadedVideoId) {
            api.videos.deleteVideo(String(uploadedVideoId))
                .then(() => {
                    setUploadedVideoId(null);
                    setUploadedVideoLink('');
                    setVideoInfo(null);
                    setVideos([]);
                    setIsConfirmDeleteModal(false);
                })
                .catch((err) => {
                    if (err.response.data.detail) {
                        show(err.response.data.detail);
                        setTimeout(() => { hide(); }, 1000);
                    }
                });
        } else if (videoInfo) {
            api.videos.deleteVideo(videoInfo.id.toString())
                .then(() => {
                    setVideoInfo(null);
                    setUploadedVideoId(null);
                    setUploadedVideoLink('');
                    setVideos([]);
                    setIsConfirmDeleteModal(false);
                })
                .catch((err) => {
                    if (err.response.data.detail) {
                        show(err.response.data.detail);
                        setTimeout(() => { hide(); }, 1000);
                    }
                });
        }
    };

    const elConfirmDeleteModal = useMemo(() => {
        if (isConfirmDeleteModal) {
            return (
                <Modal
                    onClickClose={onClickCloseModal}
                    className={cn('upload__delete-modal')}
                >
                    <UI.BoxHeader className={cn('upload__delete-modal__header')}>Предупреждение</UI.BoxHeader>
                    <UI.Content className={cn('upload__delete-modal__content')}>
                        Вы уверены, что хотите удалить загруженное видео?
                        Данное действие будет невозможно отменить
                    </UI.Content>
                    <UI.Content className={cn('upload__delete-modal__controls')}>
                        <Button
                            isSecondary={true}
                            className={cn('upload__delete-modal__controls-cancel')}
                            onClick={onClickCloseModal}
                        >
                            Отмена
                        </Button>
                        <Button
                            className={cn('upload__delete-modal__controls-cancel')}
                            onClick={onClickConfirmDelete}
                        >
                            Удалить
                        </Button>
                    </UI.Content>
                </Modal>
            );
        }
    }, [isConfirmDeleteModal]);

    const elContent = () => {
        if ((videos.length && !isContinueLoadingFiles) || (videoInfo && !isContinueLoadingFiles)) {
            return (
                <div className={cn('upload__grid')}>
                    {elConfirmDeleteModal}
                    <div className={cn('upload__content')}>
                        {elHeader}
                        {elVideoPlayer}
                    </div>
                    <UI.Sidebar className={cn('upload__sidebar')}>
                        <UI.Box padding={true} className={cn('upload__sidebar-content')}>
                            <div className={cn('upload__sidebar-content_header')}>
                                <UI.BoxHeader>Управление видео</UI.BoxHeader>
                                <Button
                                    isSecondary={true}
                                    onClick={onClickDeleteVideo}
                                    className={cn('upload__delete-button')}
                                >
                                    {t('route.upload.sidebar.delete.button')}
                                </Button>
                            </div>
                            <Button className={cn('upload__done-button')} onClick={onClickContinue}>
                                Далее
                            </Button>
                        </UI.Box>
                    </UI.Sidebar>
                </div>
            );
        }

        if ((!images.length && videos.length) || (videoInfo && !images.length)) {
            return (
                <Form registry={registry.form}>
                    <InputFile
                        name="files"
                        registryField={registry.field}
                        registry={registry}
                        onFilesLoaded={onFilesLoaded}
                        onCancelLoading={onCancelLoading}
                        external={true}
                        isHasAlbum={albumInfo}
                        isHasVideo={videoInfo}
                        isPhotoPreview={isContinueLoadingFiles}
                        uploadingInfo={uploadingInfo}
                    />
                </Form>
            );
        }

        if (images.length) {
            return (
                <div className={cn('upload__grid')}>
                    <div className={cn('upload__content')}>
                        {elHeader}
                        {elList}
                    </div>
                    <UI.Sidebar className={cn('upload__sidebar')}>
                        <UI.Box padding={true}>
                            {elSidebarActions}
                        </UI.Box>
                        <UI.Box padding={true}>
                            <UI.BoxHeader>{t('route.upload.sidebar.done.title')}</UI.BoxHeader>
                            <p className={cn('upload__done-text')}>{t('route.upload.sidebar.done.text')}</p>
                            <Button className={cn('upload__done-button')} to="/dashboard/photos">{t('route.upload.sidebar.done.button')}</Button>
                        </UI.Box>
                    </UI.Sidebar>
                </div>
            );
        }

        return (
            <>
                <Form registry={registry.form}>
                    <InputVideo
                        name="files"
                        registryField={registry.field}
                        registry={registry}
                        onFilesLoaded={onVideoFilesLoaded}
                        onCancelLoading={onCancelLoadingVideo}
                        external={true}
                        onVideoLoaded={onVideoLoaded}
                        isHasAlbum={albumInfo}
                        saveUploadingVideoParams={saveUploadingVideoParams}
                    />
                </Form>
            </>
        );
    };

    const elStatusBar = useMemo(() => {
        return (
            <div className={cn('upload__status-bar')}>
                <div className={cn('upload__status-bar-item')}>
                    <span
                        data-index={1}
                        className={cn('upload__status-text', {
                            'upload__status-text_active': match?.params.component === 'upload-video' && !images.length && !isContinueLoadingFiles
                        })}
                    >
                        {videoInfo ?
                        'Управление видео'
                        : `${t('route.upload.status.video_uploading')}`
                        }
                    </span>
                </div>
                <span className={cn('upload__divider')} />
                <div className={cn('upload__status-bar-item')}>
                    <span
                        data-index={2}
                        className={cn('upload__status-text', {
                            'upload__status-text_active': (videos.length || videoInfo) && !images.length && isContinueLoadingFiles
                        })}
                    >
                        {t('route.upload.status.video_photo_uploading')}
                    </span>
                </div>
                <span className={cn('upload__divider')} />
                <div className={cn('upload__status-bar-item')}>
                    <span
                        data-index={3}
                        className={cn('upload__status-text', {
                            'upload__status-text_active': images.length
                        })}
                    >
                        {t('route.upload.status.manage')}
                    </span>
                </div>
            </div>
        );
    }, [match?.params.component, JSON.stringify(images), JSON.stringify(videos), isContinueLoadingFiles, videoInfo]);

    const elHeader = useMemo(() => {
        if(!!images.length || !!videos.length) {
            return <h3 className={cn('input__page-header')}>{t('route.upload.content.header')}</h3>;
        }
        if (videoInfo && queryParams.album_id) {
            return (
                <>
                    <h3 className={cn('upload__page-header')}>{videoInfo.album.name}</h3>
                    <h4 className={cn('upload__page-subheader')}>
                        {videoInfo.event.name ?
                        `Событие: ${videoInfo.event.name}`
                        : ''
                        }
                    </h4>
                </>
            );
        }
    }, [images.length, videos.length, videoInfo, queryParams.album_id]);

    return (
        <UI.Main className={cn('upload')}>
            {elStatusBar}
            {!isLoadingVideoInfo && elContent()}
        </UI.Main>
    );
};

export default UploadVideo;
