import EventAPI from 'src/api/events';
import PersonAPI from 'src/api/persons';
import PartnerAPI from 'src/api/find-partner';
import AuthAPI from 'src/api/auth';
import StatisticAPI from 'src/api/statistics';
import CloudAPI from 'src/api/cloud';
import PhotosAPI from 'src/api/photos';
import CartAPI from 'src/api/cart';
import OrderAPI from 'src/api/order';
import PremiumAccountsAPI from 'src/api/premium-accounts';
import AccountsAPI from 'src/api/accounts';
import PaymentsAPI from 'src/api/payments';
import TasksAPI from 'src/api/tasks';
import SubscriptionsAPI from 'src/api/subscriptions';
import PhotographersAPI from 'src/api/photographers';
import AdsAPI from 'src/api/ads';
import AlbumsAPI from 'src/api/albums';
import SharedAlbumsAPI from 'src/api/shared-albums';
import FilesAPI from 'src/api/files';
import VideosAPI from 'src/api/videos';

import { EventAPIType } from 'src/api/events/types';
import { PersonAPIType } from 'src/api/persons/types';
import { FindPartnerAPIType } from 'src/api/find-partner/types';
import { AuthAPIType } from 'src/api/auth/types';
import { StatisticAPIType } from 'src/api/statistics/types';
import { CloudAPIType } from 'src/api/cloud/types';
import { PhotosAPIType } from 'src/api/photos/types';
import { CartAPIType } from 'src/api/cart/types';
import { OrderAPIType } from 'src/api/order/types';
import { PremiumAccountsAPIType } from 'src/api/premium-accounts/types';
import { AccountsAPIType } from 'src/api/accounts/types';
import { PaymentsAPIType } from 'src/api/payments/types';
import { TasksAPIType } from 'src/api/tasks/types';
import { SubscriptionsAPIType } from 'src/api/subscriptions/types';
import { PhotographersAPIType } from 'src/api/photographers/types';
import { AdsAPIType } from 'src/api/ads/types';
import { AlbumsAPIType } from 'src/api/albums/types';
import { SharedAlbumsAPIType } from 'src/api/shared-albums/types';
import { FilesAPIType } from 'src/api/files/types';
import { VideosAPIType } from 'src/api/videos/types';

export type APIType = {
    events: EventAPIType;
    persons: PersonAPIType;
    partner: FindPartnerAPIType;
    auth: AuthAPIType;
    statistic: StatisticAPIType;
    cloud: CloudAPIType;
    photos: PhotosAPIType;
    cart: CartAPIType;
    order: OrderAPIType;
    premium: PremiumAccountsAPIType;
    accounts: AccountsAPIType;
    payments: PaymentsAPIType;
    tasks: TasksAPIType;
    subscriptions: SubscriptionsAPIType;
    photographers: PhotographersAPIType;
    ads: AdsAPIType;
    albums: AlbumsAPIType;
    shared: SharedAlbumsAPIType;
    files: FilesAPIType;
    videos: VideosAPIType;
};

export default {
    events: EventAPI,
    persons: PersonAPI,
    partner: PartnerAPI,
    auth: AuthAPI,
    statistic: StatisticAPI,
    cloud: CloudAPI,
    photos: PhotosAPI,
    cart: CartAPI,
    order: OrderAPI,
    premium: PremiumAccountsAPI,
    accounts: AccountsAPI,
    payments: PaymentsAPI,
    tasks: TasksAPI,
    subscriptions: SubscriptionsAPI,
    photographers: PhotographersAPI,
    ads: AdsAPI,
    albums: AlbumsAPI,
    shared: SharedAlbumsAPI,
    files: FilesAPI,
    videos: VideosAPI
} as APIType;
