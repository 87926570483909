import React, { useEffect, useMemo, useState, useCallback, Fragment, ReactNode, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { useClassnames } from 'hook/use-classnames';
import useIntersect from 'hook/use-intersect';
import { useCancelTokens } from 'component/core/cancel-token';
import { mergePerson } from 'component/api/main';
import UI from 'component/ui';
import Loader from 'component/loader';
import IconGalochki from 'component/icon/galochki';
import MiniInfo from 'component/mini-info';
import Button from 'component/button';
import debounce from 'lodash.debounce';
import { IStore } from 'store/reducers/types/reducers';
import PersonEdit from 'component/person-edit';
import Form, { useRegistry } from 'component/form';
import Input from 'component/form/input';
import InputLocation from 'component/form/input-location';
import { key as keyLocalConfig } from 'store/reducers/localConfig/reducer';
import { key as keyUser } from 'store/reducers/user/reducer';
import { setSimilarPersonList } from 'store/reducers/localConfig/actions';
import style from './index.pcss';
import { useAlert } from 'component/alert/provider';
import api from 'src/api';
import { Person, PersonItem, PersonsSearchFilter } from 'src/api/persons/types';
import ReactTooltip from 'react-tooltip';
import PersonMerge from 'component/person-merge';
import { Page } from 'src/api/base';

const PERSONS_LIMIT = 30;
const Persons = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, tokenPerson, tokenMerge, tokenEditUser, tokenUserSelfInfo, tokenPersons] = useCancelTokens(6);
    const registryFilterForm = useRegistry();
    const { show } = useAlert();
    const dispatch = useDispatch();

    const similarPersonList = useSelector<IStore, boolean>((store) => store[keyLocalConfig].similarPersonList);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    const [myPerson, setMyPerson] = useState<Array<PersonItem>>([]);
    const [pendingBefore, setPendingBefore] = useState<boolean>(false);
    const [mergePending, setMergePending] = useState<boolean>(false);
    const [modalPending, setModalPending] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isMerge, setIsMerge] = useState<boolean>(false);
    const [showActions, setShowActions] = useState<boolean>(false);
    const [checked, setChecked] = useState<Array<number>>([]);
    const [checkedNamesCount, setCheckedNamesCount] = useState<number>(0);
    const [person, setPerson] = useState<Person | null>(null);
    const [errorMerge, setErrorMerge] = useState<string | null>(null);
    const [validity, setValidity] = useState<boolean>(false);

    const [personsList, setPersonsList] = useState<Array<PersonItem>>([]);
    const [personsTotal, setPersonsTotal] = useState<number>(0);
    const [isPersonsLoadMore, setIsPersonsLoadMore] = useState<boolean>(false);
    const [isPersonsPending, setIsPersonsPending] = useState<boolean>(false);
    const [isPersonsMerge, setIsPersonsMerge] = useState<boolean>(false);
    const [isPersonsNext, setIsPersonsNext] = useState<boolean>(false);
    const [personsPage, setPersonsPage] = useState<number>(1);

    const onShowHideSimilarPersonList = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(setSimilarPersonList(!similarPersonList));
    };

    useEffect(() => {
        // _request();

        return () => {
            token.remove();
            tokenPerson.remove();
            tokenMerge.remove();
            tokenEditUser.remove();
            tokenUserSelfInfo.remove();
            tokenPersons.remove();
        };
    }, []);

    useEffect(() => {
        if (isPersonsPending || isPersonsLoadMore) {
            const formData = registryFilterForm.form.getPayload();

            const page: Page = {
                pageNumber: personsPage,
                pageSize: PERSONS_LIMIT
            };
            const filter: PersonsSearchFilter = {
                    user_id: userId,
                    ...(formData.name && { search: formData.name }),
                    ...(formData.location?.value && { location_id: formData.location?.value })
            };

            api.persons.getPersonsList(page, filter)
            .then((resp) => {
                setPersonsList(
                    isPersonsMerge ?
                        [...personsList, ...resp.data.results] : resp.data.results
                );
                setPersonsTotal(resp.data.count);
                setIsPersonsNext(!Boolean(resp.data.next === null));
            })
            .finally(() => {
                setIsPersonsPending(false);
                setIsPersonsLoadMore(false);
            })
            .catch(() => {
                setIsPersonsNext(false);
                setIsPersonsPending(false);
                setIsPersonsLoadMore(false);
            });
        }
    }, [isPersonsPending, isPersonsLoadMore]);

    const _requestMerge = (): void => {
        setMergePending(true);
        setErrorMerge(null);
        mergePerson({
            cancelToken: tokenMerge.new(),
            data: {
                persons_ids: checked
            }
        })
            .then(() => {
                setPersonsPage(1);
                setIsPersonsMerge(false);
                setIsPersonsPending(true);

                show('Ваши изменения отправлены на модерацию, после принятия администратором данные будут изменены.');
                setShowActions(false);
                setMergePending(false);
                setChecked([]);
                setCheckedNamesCount(0);
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    console.error((error));
                    show('Не удалось отправить заявку на модерацию. Возможно, заявка на объединение с выбранной персоной уже зарегистрирована.', 'warning');
                    setErrorMerge(error);
                    setMergePending(false);
                }
            });
    };

    const $bottomPreviousPosts = useIntersect((entry) => {
        if (entry.isIntersecting && isPersonsNext && !isPersonsPending) {
            setIsPersonsMerge(true);
            setPersonsPage((prevState) => prevState + 1);
            setIsPersonsPending(true);
        }
    }, {
        rootMargin: '100px 0px'
    });

    const onClickButtonBefore = (e: MouseEvent): void => {
        e.preventDefault();

        // _request(false, true);
    };

    const onChangeForm = debounce(useCallback(() => {
        if (registryFilterForm.form.checkValidity()) {
            setPersonsPage(1);
            setIsPersonsMerge(false);
            setIsPersonsPending(true);
        }
    }, [validity, isPersonsPending]), 300);

    const onChangePerson = () => {
        // _request();

        // show('Запрос подтверждения отправлен на модерацию, после принятия администратором данные будут изменены.');
    };

    const showModal = (id: number): void => {
        setModalPending(true);

        api.persons.getPersonItem(id).then((resp) => {
            setPerson(resp.data);
            setModalPending(false);
        });
    };

    const onClickClose = (): void => {
        setPerson(null);
        setIsEdit(false);
        setIsMerge(false);
    };

    const onClickEdit = (id: number) => (): void => {
        setIsEdit(true);
        showModal(id);
    };

    const onClickMerge = (id: number) => (): void => {
        setIsMerge(true);
        showModal(id);
    };

    const elModal = useMemo(() => {
        if (isEdit && person) {
            return <PersonEdit pending={modalPending} onCloseModal={onClickClose} person={person} />;
        }

        if (isMerge && person) {
            return <PersonMerge pending={modalPending} onCloseModal={onClickClose} person={person} />;
        }
    }, [modalPending, isEdit, isMerge, JSON.stringify(person)]);

    const _showActions = useCallback(() => {
        setShowActions(true);
    }, []);

    const _hideActions = useCallback(() => {
        setShowActions(false);
    }, []);

    const elActions = useMemo(() => {
        if (!personsList.length) {
            return null;
        }

        let content: ReactNode = (
            <Fragment>
                <h3 className={cn('persons__page-header')}>Действия</h3>
                <div onClick={_showActions} className={cn('persons__action')}>
                    <IconGalochki className={cn('persons__action-icon')} />
                    <span className={cn('persons__action-text')}>Объединить несколько персон</span>
                </div>
            </Fragment>
        );

        if (showActions) {
            const placeholderMerge = checked.length === 1
                ? 'Укажите еще несколько персон'
                : '';

            if (checkedNamesCount > 1) {
                show('Нельзя объединить две и более известные персоны', 'warning');
            }

            content = (
                <Fragment>
                    <h3 className={cn('persons__page-header')}>Персон выбрано: {checked.length}</h3>
                    <span className={cn('persons__header-placeholder', 'error')}>{placeholderMerge}</span>
                    <Button
                        onClick={_requestMerge}
                        disabled={!(checked.length > 1) || mergePending || checkedNamesCount > 1}
                        isLoading={mergePending}
                        className={cn('persons__action-button')}
                    >
                        Объединить персоны
                    </Button>
                    <Button
                        onClick={_hideActions}
                        className={cn('persons__action-button')}
                        isSecondary={true}
                        disabled={mergePending}
                    >
                        Отменить
                    </Button>
                </Fragment>
            );
        }

        return (
            <UI.Box padding={true} className={cn('persons__box')}>
                {content}
            </UI.Box>
        );
    }, [showActions, JSON.stringify(checked), errorMerge, mergePending, checkedNamesCount, JSON.stringify(personsList)]);

    const onClickCheckbox = (id: number, isAssignedName: boolean) => (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        const index = checked.indexOf(id);
        let newList = [...checked];

        if (index > -1) {
            newList = newList.filter((item) => item !== id);
            if (isAssignedName && checkedNamesCount > 0) {
                setCheckedNamesCount(checkedNamesCount - 1);
            }
        } else {
            newList.push(id);
            if (isAssignedName) {
                setCheckedNamesCount(checkedNamesCount + 1);
            }
        }

        setChecked(newList);
    };

    const elCheckbox = (isChecked: boolean, id: number, isAssignedName: boolean) => {
        if (showActions) {
            return (
                <div
                    className={cn('persons__checkbox-absolute')}
                    onClick={onClickCheckbox(id, isAssignedName)}
                >
                    <div
                        className={cn('persons__checkbox-inner', {
                            'persons__checkbox-inner_checked': isChecked
                        })}
                    />
                </div>
            );
        }
    };

    const elContent = useMemo(() => {
        if (isPersonsPending && !personsList?.length) {
            return <Loader />;
        }

        if (personsList?.length) {
            return (
                <div className={cn('persons__content')}>
                    {personsList.map((item) => {
                        const isChecked = checked.indexOf(item.id) > -1;
                        const isAssignedName = Boolean(item.last_name || item.first_name);

                        return (
                            <div key={item.id}>
                                <div
                                    className={cn('persons__item', {
                                        'persons__item_checkbox': showActions
                                    })}
                                    key={item.id}
                                >
                                    <ReactTooltip />
                                    <div className={cn('persons__item_avatar')}>
                                        {elCheckbox(isChecked, item.id, isAssignedName)}
                                        <MiniInfo
                                            title={(item.first_name && item.last_name) ? item.full_name : item.id}
                                            avatar={{
                                                imgSrc: item.photo,
                                                imgAlt: (item.first_name && item.last_name) ? item.full_name : item.id.toString()
                                            }}
                                            // desc={'Возраст не указан'}
                                        />
                                    </div>
                                    <div className={cn('persons__text')}>{item.location_name || 'Город не указан'}</div>
                                    {/*<div className={cn('persons__item__icon')}>*/}
                                    {/*    <FontAwesomeIcon data-tip={'Объединить персону'} icon={faPeopleArrows} onClick={onClickMerge(personItem.id)} />*/}
                                    {/*</div>*/}
                                    <div className={cn('persons__item__icon')}>
                                        <FontAwesomeIcon data-tip={'Рассказать о персоне'} icon={faEdit} onClick={onClickEdit(item.id)} />
                                        <FontAwesomeIcon data-tip={'Объединить персону с похожей'} icon={faPeopleArrows} onClick={onClickMerge(item.id)} />
                                    </div>
                                    {/* <div
                                        onClick={onShowHideSimilarPersonList}
                                        className={cn('persons__item_arrow', {
                                            'persons__item_rotate': !similarPersonList,
                                            'persons__item_hide': !personItem
                                        })}
                                        title="Показать похожих"
                                    >
                                        <ArrowDown />
                                    </div> */}
                                </div>
                                {/* {similarPersonList && (
                                    <SimilarPersonList
                                        person={{
                                            id: personItem.id,
                                            first_name: personItem.full_name.split('')[0] || undefined,
                                            last_name: personItem.full_name.split('')[1] || undefined,
                                            photo_url: personItem.photo || undefined
                                        }}
                                        similar_persons={
                                            []
                                        }
                                    />
                                )} */}
                            </div>
                        );
                    })}
                </div>
            );
        }

        if (!personsList?.length && !isPersonsPending) {
            return <p>Нет распознанных персон</p>;
        }
    }, [JSON.stringify(personsList), isPersonsPending, showActions, JSON.stringify(checked), myPerson, similarPersonList]);

    const elButtonBeforePosts = () => {
        if (!isPersonsPending && personsList?.length && isPersonsNext) {

            if (personsList?.length === personsTotal) {
                return <Loader ref={$bottomPreviousPosts} />;
            }
        }
    };

    return (
        <UI.Main className={cn('persons')}>
            {elModal}
            <UI.Content className={cn('persons__grid')}>
                <UI.Box padding={true} className={cn('persons__box')}>
                    <h3 className={cn('persons__page-header')}>{t('route.persons.content.header')}</h3>
                    <span className={cn('persons__header-placeholder')}>
                        Выберите персону для указания данных о ней
                    </span>
                    {elContent}
                    {elButtonBeforePosts()}
                </UI.Box>
                <div className={cn('persons__sidebar')}>
                    {/* {elActions} */}
                    <UI.Box padding={true} className={cn('persons__box')}>
                        <h3 className={cn('persons__page-header')}>{t('route.persons.sidebar.header')}</h3>
                        <Form
                            registry={registryFilterForm.form}
                            onChange={onChangeForm}
                            onChangeValidity={setValidity}
                        >
                            <Input
                                registry={registryFilterForm.field}
                                name="name"
                                type="text"
                                direction="column"
                                children={t('global.form.items.person')}
                            />
                            <InputLocation
                                registry={registryFilterForm.field}
                                name="location"
                                clearable={true}
                                children={t('global.form.items.city')}
                                direction="column"
                            />
                        </Form>
                    </UI.Box>
                </div>
            </UI.Content>
        </UI.Main>
    );
};

// tslint:disable-next-line max-file-line-count
export default Persons;
