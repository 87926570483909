import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { Page, PaginationResponse } from '../base';
import { OrderAPIType, OrderCreated, OrderList, OrderListFilter, OrderPhotoStatus, OrderRetrieve } from './types';

const getOrderPhotoStatus = (id: number): Promise<AxiosResponse<OrderPhotoStatus>> => {
    return httpClient.get<OrderPhotoStatus>(`/api/v2/orders/photos/${id}/status/`);
};

const getOrderList = (
    page: Page,
    filter?: OrderListFilter
): Promise<AxiosResponse<PaginationResponse<OrderList>>> => {
    return httpClient.get<PaginationResponse<OrderList>>('/api/v2/orders/', {
        params: {
            page: page.pageNumber,
            page_size: page.pageSize || 20,
            ...(filter && {
                search: filter.search,
                payment_status: filter.payment_status
            })
        }
    });
};

const createOrder = (reference_price: string): Promise<AxiosResponse<OrderCreated>> => {
    return httpClient.post<OrderCreated>('/api/v2/orders/', {
        reference_price
    });
};

const getOrderItem = (id: number): Promise<AxiosResponse<OrderRetrieve>> => {
    return httpClient.get<OrderRetrieve>(`/api/v2/orders/${id}/`);
};

export default {
    getOrderPhotoStatus,
    getOrderList,
    createOrder,
    getOrderItem
} as OrderAPIType;
