import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { FilesVideoItem, CreateFilesVideoData, FilesAPIType } from './types';

const createFilesVideo = (data: CreateFilesVideoData): Promise<AxiosResponse<FilesVideoItem>> => {
    return httpClient.post<FilesVideoItem>('/api/v2/files/videos/', data);
};

const getFilesVideoRetrieve = (id: string): Promise<AxiosResponse<FilesVideoItem>> => {
    return httpClient.get<FilesVideoItem>(`/api/v2/files/videos/${id}/`);
};

export default {
  createFilesVideo,
  getFilesVideoRetrieve
} as FilesAPIType;
