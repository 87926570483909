import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import LazyLoad from 'component/lazy-load';
import Attachments from 'component/attachments';

import { IProps } from './types';
import style from './index.pcss';

const SearchResult: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const history = useHistory();

    const [linkEvent, setLinkEvent] = useState<string>(`${props.link}`);

    const elDate = useMemo(() => {
        if(props.date) {
            return (
                <div className={cn('search-result__info-item')}>
                    <span className={cn('search-result__info-text')}>{t('components.result.date')}</span>
                    <span className={cn('search-result__info-value')}>{props.date}</span>
                </div>
            );
        }
    }, [props.date]);

    const elPlace = useMemo(() => {
        if(props.place) {
            return (
                <div className={cn('search-result__info-item')}>
                    <span className={cn('search-result__info-text')}>{t('components.result.place')}</span>
                    <span className={cn('search-result__info-value')}>{props.place}</span>
                </div>
            );
        }
    }, [props.place]);

    const elInfo = useMemo(() => {
        return (
            <div className={cn('search-result__info')}>
                {elDate}
                {elPlace}
            </div>
        );
    }, [props.date, props.place, JSON.stringify(props.attachments), JSON.stringify(props.persons_count)]);

    const elAttachments = useMemo(() => {
        if(props.attachments) {
            return (
                <LazyLoad>
                    <Attachments
                        limit={4}
                        link={linkEvent}
                        isLink={true}
                        personsIds={props.persons}
                        attachmentsCount={props.photos_count || props.attachments.length}
                        className={cn('search-result__attachments')}
                        query={props.query}
                    >
                        {props.attachments}
                    </Attachments>
                </LazyLoad>
            );
        }
        if(props.photo_attachments) {
            return (
                <LazyLoad>
                    <Attachments
                        limit={4}
                        link={linkEvent}
                        isLink={true}
                        personsIds={props.persons}
                        attachmentsCount={props.photos_count || props.photo_attachments.length}
                        className={cn('search-result__attachments')}
                        query={props.query}
                        isNewFormat={true}
                    >
                        {props.photo_attachments.map((item) => {
                            return {url: item};
                        })}
                    </Attachments>
                </LazyLoad>
            );
        }
    }, [JSON.stringify(props.attachments), JSON.stringify(props.photo_attachments), props.photos_count, props.persons, linkEvent]);

    useEffect(() => {
        let newLink = `${props.link}?`;

        if (props.persons?.length) {
            const person_ids = props.persons.reduce((acc: string, id: number) => (`${acc}&person_ids=${id}`), '');
            newLink += person_ids;
        }

        if (props.query) {
            newLink += `&${props.query}`;
        }

        if (props.file_id) {
            newLink += `&file_id=${props.file_id}`;
        }

        setLinkEvent(newLink);
    }, [props.persons, props.query, props.file_id]);

    return (
        <UI.Box className={cn('search-result')} padding={true}>
            <Link to={linkEvent} className={cn('search-result__header')}>{props.title}</Link>
            {elAttachments}
            {elInfo}
        </UI.Box>
    );
};

export default SearchResult;
