import React, { useState, useEffect, useMemo, useCallback, ReactNode, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useClassnames } from 'hook/use-classnames';
import { useTranslation } from 'react-i18next';
import { useRespondAd } from 'hook/use-respond-ad';
import { IStore } from 'store/reducers/types/reducers';
import { CartItemsStore, IStore as IStoreCart } from 'store/reducers/cart/types/reducer';
import { key as keyUser } from 'store/reducers/user/reducer';
import { key as cartKey } from 'store/reducers/cart/reducer';
// import SearchPartnerModal from '../search-partner/search-partner-modal';
import UI from 'component/ui';
import style from './index.pcss';
import Button from 'component/button';
import Banner from 'component/banner';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Photo, PhotoItem, PhotosSearchFilter } from 'src/api/photos/types';
import api from 'src/api';
import axios from 'axios';
import moment from 'moment';
import { parse, stringify } from 'query-string';
import { normalizeObject } from 'component/helper/normalize-object';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { addCart } from 'src/store/reducers/cart/actions';
import { OrderPhotoStatus } from 'src/api/order/types';
import { DataAlbumSalesItem } from 'component/api/types/api/payment/sale/get-photo-sales-by-photo-id/get/code-200';
import { useCancelToken } from 'component/core/cancel-token';
import { getPhotoSaleByPhotoId } from 'component/api/payment';
import { Link } from 'react-router-dom';
import Breadcrumbs from 'component/breadcrumbs';
import { PersonItem, PersonsSearchFilter } from 'src/api/persons/types';
import PersonList from 'component/person-list';
import { Page } from 'src/api/base';
import PhotoCarousel from 'component/photoCarousel';
import debounce from 'lodash.debounce';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import useIntersect from 'hook/use-intersect';
import { useAlert } from 'component/alert/provider';
import { PersonPhotoItem } from 'route/person/photo/person-photo-item';
import { CartItem } from 'src/api/cart/types';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};
const bytesToMegaBytes = (bytes: number) => (bytes / (1024 * 1024)).toFixed(2);
const getColor = (photoColorId: number): string => {
    let styleName = '';
    const blue_id = 6;
    const red_id = 1;
    const white_id = 9;
    const orange_id = 2;
    const yellow_id = 3;
    const cyan_id = 5;
    const green_id = 4;
    const violet_id = 7;
    const black_id = 8;

    switch (photoColorId) {
        case blue_id: styleName = 'blue'; break;
        case red_id: styleName = 'red'; break;
        case white_id: styleName = 'white'; break;
        case orange_id: styleName = 'orange'; break;
        case yellow_id: styleName = 'yellow'; break;
        case cyan_id: styleName = 'cyan'; break;
        case green_id: styleName = 'green'; break;
        case violet_id: styleName = 'violet'; break;
        case black_id: styleName = 'black'; break;
    }

    return styleName;
};

const PERSONS_LIMIT = 30;

const PHOTOS_LIMIT = 9;

const Photo = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const history = useHistory();
    const { albumId, photoId }: { albumId: string, photoId: string } = useParams();
    const dispatch = useDispatch();
    const token = useCancelToken();
    const [onClickRespondCheck, elMessagesSubscription] = useRespondAd();
    const { show, hide } = useAlert();

    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    // store
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const cart = useSelector<IStore, IStoreCart>((store) => store[cartKey]);
    const isMobile = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].mobile);
    const isTablet = useSelector<IStore, boolean>((store) => store[keyDeviceInfo].tablet);

    // Sale
    const saleToken = useCancelToken();
    const [albumSales, setAlbumSales] = useState<Array<DataAlbumSalesItem> | null>(null);
    const [defaultSales, setDefaultSales] = useState<Array<DataAlbumSalesItem> | null>(null);

    // Persons
    const [personList, setPersonList] = useState<Array<PersonItem>>([]);

    const [currentPhoto, setCurrentPhoto] = useState<PhotoItem>();
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>();
    const [photoStatus, setPhotoStatus] = useState<OrderPhotoStatus>();
    const [photoList, setPhotoList] = useState<Array<Photo>>([]);
    const [photoTotal, setPhotoTotal] = useState<number>();
    const [startPage, setStartPage] = useState<number>(1);
    const [prevPage, setPrevPage] = useState<number | null>();
    const [nextPage, setNextPage] = useState<number | null>();
    const [isLoadingNext, setIsLoadingNext] = useState<boolean>(false);
    const [isLoadingPrev, setIsLoadingPrev] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDownloadAvailable, setIsDownloadAvailable] = useState<boolean>(true);
    const [isDownloadPending, setIsDownloadPending] = useState<boolean>(false);

    const [cartItemsListPage, setCartItemsListPage] = useState<number>(1);
    const [isCartItemsListLoading, setIsCartItemsListLoading] = useState<boolean>(false);
    const [isCartItemsListLoadMore, setIsCartItemsListLoadMore] = useState<boolean>(false);
    const [cartItemsList, setCartItemsList] = useState<Array<CartItem>>([]);

    useEffect(() => {
        if (isCartItemsListLoading || isCartItemsListLoadMore) {
            api.cart.getCartItemsList({
                pageNumber: cartItemsListPage
            })
            .then((resp) => {
                const filteredList: CartItemsStore = {
                    count: 0,
                    items: []
                };

                setCartItemsList((prev) => isCartItemsListLoadMore ?
                    [...prev, ...resp.data.results] : resp.data.results);
                if (!resp.data.next) {
                    const newList = isCartItemsListLoadMore ? [...cartItemsList, ...resp.data.results].map((item) => filteredList.items.push(item))
                        : resp.data.results.map((item) => filteredList.items.push(item));
                    filteredList.count = resp.data.count;
                    dispatch(addCart(filteredList));
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);
                } else {
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);

                    setCartItemsListPage((prev) => prev + 1);
                    setIsCartItemsListLoadMore(true);
                }
            });
        }
    }, [isCartItemsListLoading, isCartItemsListLoadMore]);

    const photosParams: PhotosSearchFilter = {
        person_id: queryParams.person_id,
        is_stock: queryParams.is_stock,
        number_id: queryParams.number,
        search: queryParams.search,
        time_after: queryParams.time_from,
        time_before: queryParams.time_to,
        date_after: queryParams.event_date_from,
        date_before: queryParams.event_date_to,
        color_id: queryParams.color_id,
        photographer_id: queryParams.photographer_id,
        album_id: [Number(albumId)]
    };

    const [isShowBreadcrumbs, setIsShowBreadcrumbs] = useState<boolean>(false);

    const onChangeWindowSize = debounce(useCallback(() => {
        if (window.innerWidth <= 1023) {
            return setIsShowBreadcrumbs(true);
        }

        setIsShowBreadcrumbs(false);
    }, [window.innerWidth]), 1000);

    useEffect(() => {
        window.addEventListener('resize', onChangeWindowSize);

        return (() => {
            window.removeEventListener('resize', onChangeWindowSize);
        });
    });

    const $rightNextPhotos = useIntersect((entry) => {
        if(entry.isIntersecting && nextPage) {
            setIsLoadingNext(true);
            api.photos.getPhotosList(
                {
                    pageNumber: nextPage,
                    pageSize: PHOTOS_LIMIT
                },
                    photosParams
                )
                .then((resp) => {
                    setPhotoList((prev) => [...prev, ...resp.data.results]);
                    setNextPage(resp.data.next);
                })
                .finally(() => setIsLoadingNext(false));
        }
    }, {
        rootMargin: '0px 25px'
    });

    let startPhotoList: Array<Photo> = [];

    useEffect(() => {
        let initialPreviousPage: number | null = null;
        let initialNextPage: number | null = null;

        setIsLoading(true);
        api.photos.getPhotosList(
            {
                pageSize: PHOTOS_LIMIT
            }, {
                cursor: Number(photoId),
                ...(photosParams)
            })
            .then((resp) => {
                startPhotoList = resp.data.results.concat(startPhotoList);
                setPhotoTotal(resp.data.count);
                initialPreviousPage = resp.data.previous;
                initialNextPage = resp.data.next;
                if (resp.data.previous) {
                    setStartPage(resp.data.previous + 1);
                } else if (resp.data.next) {
                    setStartPage(resp.data.next - 1);
                }

                if (initialPreviousPage) {
                    api.photos.getPhotosList(
                        {
                            pageNumber: initialPreviousPage,
                            pageSize: PHOTOS_LIMIT
                        },
                            photosParams
                        )
                        .then((payload) => {
                            setPrevPage(payload.data.previous);
                            startPhotoList = payload.data.results.concat(startPhotoList);
                            if (initialNextPage) {
                                api.photos.getPhotosList(
                                    {
                                        pageNumber: initialNextPage,
                                        pageSize: PHOTOS_LIMIT
                                    },
                                        photosParams
                                    )
                                    .then((data) => {
                                        setNextPage(data.data.next);
                                        startPhotoList = startPhotoList.concat(data.data.results);

                                        startPhotoList.forEach((item, index) => {
                                            if (item.id === Number(photoId)) {
                                                setCurrentPhotoIndex(index);
                                            }
                                        });
                                    })
                                    .then(() => setPhotoList(startPhotoList));
                            } else {
                                startPhotoList.forEach((item, index) => {
                                    if (item.id === Number(photoId)) {
                                        setCurrentPhotoIndex(index);
                                    }
                                });

                                setPhotoList(startPhotoList);
                            }
                        });
                } else if (initialNextPage) {
                    api.photos.getPhotosList(
                        {
                            pageNumber: initialNextPage,
                            pageSize: PHOTOS_LIMIT
                        },
                            photosParams
                        )
                        .then((payload) => {
                            setNextPage(payload.data.next);
                            startPhotoList = startPhotoList.concat(payload.data.results);
                            startPhotoList.forEach((item, index) => {
                                if (item.id === Number(photoId)) {
                                    setCurrentPhotoIndex(index);
                                }
                            });
                        })
                        .then(() => setPhotoList(startPhotoList));
                } else {
                    startPhotoList.forEach((item, index) => {
                        if (item.id === Number(photoId)) {
                            setCurrentPhotoIndex(index);
                        }
                    });

                    setPhotoList(startPhotoList);
                }
                setIsLoading(false);
            });

        history.replace({
            pathname: `/invited-albums/${albumId}/${photoId}`,
            search: stringify(queryParams, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });

        api.photos.getPhotoItem(Number(photoId))
            .then((resp) => {
                setCurrentPhoto(resp.data);
            });
    }, []);

    const _requestPhotoSales = () => {
        getPhotoSaleByPhotoId({
            cancelToken: saleToken.new(),
            params: {
                photo_id: photoId
            }
        }).then((resp) => {
            setAlbumSales(resp.album_sales ? resp.album_sales : null);
            setDefaultSales(resp.default_sales ? resp.default_sales : null);
        }).catch((err) => {
            if (!axios.isCancel(err)) {
                console.error(err);
            }
        });
    };

    const _requestPersons = () => {
        const page: Page = {
            pageNumber: 1,
            pageSize: PERSONS_LIMIT
        };

        const filter: PersonsSearchFilter = {
            photo_id: Number(photoId)
        };

        api.persons.getPersonsList(page, filter)
            .then((resp) => {
                setPersonList(resp.data.results);
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    const _requestPhotoStatus = () => {
        api.order.getOrderPhotoStatus(Number(photoId))
            .then((resp) => setPhotoStatus(resp.data));
    };

    const _requestCreateCartItem = () => {
        if (currentPhoto) {
            api.cart.createCartItem('photo', currentPhoto.id)
                .then(() => {
                    setCartItemsListPage(1);
                    setIsCartItemsListLoading(true);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    const _requestDeleteCartItem = () => {
        const cartItemId = cart.items?.find((item) => item.photo.id === currentPhoto?.id)?.id;

        if (currentPhoto && cartItemId) {
            api.cart.destroyCartItem(cartItemId)
               .then(() => {
                    setCartItemsListPage(1);
                    setIsCartItemsListLoading(true);
                })
               .catch((err) => {
                    console.error(err);
                });
        }
    };

    useEffect(() => {
        _requestPersons();
        _requestPhotoSales();
        _requestPhotoStatus();
    }, [photoId]);

    const onClickAddToCart = (): void => {
        if (!isAuth) {
            history.push(`/login?from=${history.location.pathname}${history.location.search}`);
        }

        _requestCreateCartItem();
    };

    const onClickRemoveFromCart = (): void => {
        _requestDeleteCartItem();
    };

    const preparePhotoSales = (sales: Array<DataAlbumSalesItem>): Array<number> => {
        let sale1 = 0;
        let sale2 = 0;
        let sale3 = 0;

        sales.forEach((sale) => {
            switch (sale.photo_count) {
                case 'THREE_OR_MORE':
                    sale1 = sale.value;
                    break;
                case 'SIX_OR_MORE':
                    sale2 = sale.value;
                    break;
                case 'MORE_THAN_TEN':
                    sale3 = sale.value;
                    break;
            }
        });

        return [sale1, sale2, sale3];
    };

    const elSaleInfo = useMemo(() => {
        return (
            <div className={cn('person-photo__sale-recommendations')}>
                <span>
                    У фотографа <b>{currentPhoto?.photographer?.first_name} {currentPhoto?.photographer?.last_name}</b> действует скидка на фотографии.
                </span>
                {albumSales && (
                    <div className={cn('person-photo__sale-table')}>
                        <span>На фотографии из данного события:</span>
                        <div className={cn('person-photo__sale-table-header')}>
                            <div>3-5 фото</div>
                            <div>6-10 фото</div>
                            <div>более 10 фото</div>
                        </div>
                        <div className={cn('person-photo__sale-table-body')}>
                            {
                                preparePhotoSales(albumSales).map((sale, index) => {
                                    return (
                                        <div key={index}>{sale}%</div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )}
                {defaultSales && (
                    <div className={cn('person-photo__sale-table')}>
                        <span>Общая скидка*:</span>
                        <div className={cn('person-photo__sale-table-header')}>
                            <div>3-5</div>
                            <div>6-10</div>
                            <div>более 10</div>
                        </div>
                        <div className={cn('person-photo__sale-table-body')}>
                            {
                                preparePhotoSales(defaultSales).map((sale, index) => {
                                    return (
                                        <div key={index}>{sale}%</div>
                                    );
                                })
                            }
                        </div>
                        <span>* - данная скидка не суммируется с индивидуальной скидкой на событие.</span>
                    </div>
                )}
            </div>
        );
    }, [JSON.stringify(currentPhoto), albumSales, defaultSales]);

    const onClickDownload = (): void => {
        setIsDownloadPending(true);
        api.photos.createDownload(Number(photoId))
            .then((resp) => {
                const href = URL.createObjectURL(resp.data);

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${currentPhoto?.id}.jpeg`);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 403:
                        show('Вы превысили максимальное количество скачиваний', 'warning');
                        setIsDownloadAvailable(false);

                        setTimeout(() => { hide(); }, 5000);
                        break;
                }
            })
            .finally(() => {
                setIsDownloadPending(false);
            });
    };

    const elSellBlock = useMemo((): ReactNode => {
        if (currentPhoto) {
            const alreadyPurchased = photoStatus?.payment_status === 'PAYED';
            const alreadyInCart = cart.items?.find((item) => item.photo.id === currentPhoto.id);
            const mainButtonProps = {
                onClick  : alreadyInCart ? undefined : onClickAddToCart,
                to       : alreadyInCart ? '/cart' : undefined,
                className: cn('photo__button'),
                disabled : !currentPhoto || alreadyPurchased,
                children : alreadyInCart ? t('route.photo.sidebar.already-in-cart') : alreadyPurchased ? t('route.photo.sidebar.already-purchased') : t('route.photo.sidebar.add-to-cart')
            };

            if (currentPhoto.download_url && isDownloadAvailable && !alreadyPurchased) {
                return (
                    <UI.Box>
                        <div className={cn('person-photo__download')}>
                            {
                                isAuth && (
                                    <Button onClick={onClickDownload} disabled={isDownloadPending}>
                                        {isDownloadPending ? 'Загрузка...' : 'Скачать фото'}
                                    </Button>
                                )
                            }
                            {
                                !isAuth && (
                                    <Fragment>
                                        <ReactTooltip />
                                        <div data-tip={'Чтобы скачать фото, пожалуйста, авторизируйтесь.'}>
                                            <Button disabled={true}>
                                                Скачать фото
                                            </Button>
                                        </div>
                                    </Fragment>
                                )
                            }
                        </div>
                    </UI.Box>
                );
            }

            return (
                <UI.Box padding={true} className={cn('person-photo__sell')}>
                    <div className={cn('person-photo__sell-price')}>
                        <b>{Number(currentPhoto?.price)}₽</b>
                    </div>
                    <div className={cn('person-photo__sell-container')}>
                        <div className={cn('person-photo__sell-btn')}>
                            {!!alreadyInCart && (
                                <Button
                                    className={cn('photo__button', 'photo__button_remove')}
                                    isSecondary={true}
                                    onClick={onClickRemoveFromCart}
                                >
                                    {t('route.photo.sidebar.remove-from-cart')}
                                </Button>
                            )}
                            <Button {...mainButtonProps} />
                        </div>
                        {(albumSales || defaultSales) && (
                            <div className={cn('person-photo__sell-sale')}>
                                <ReactTooltip
                                    id="photo-sale"
                                    place="left"
                                    effect="solid"
                                    border={true}
                                    borderColor="#dee1e4"
                                    backgroundColor="#fff"
                                    className={cn('person-photo__sale-tooltip')}
                                >
                                    {elSaleInfo}
                                </ReactTooltip>
                                <span>
                                    <b>% СКИДКИ</b> <FontAwesomeIcon data-tip={true} data-type="light" data-for="photo-sale" icon={faQuestionCircle} />
                                </span>
                            </div>
                        )}
                    </div>
                </UI.Box>
            );
        }
    }, [JSON.stringify(currentPhoto), JSON.stringify(cart), albumSales, defaultSales, photoStatus, isDownloadAvailable, isDownloadPending]);

    const elPhotoTime = useMemo(() => {
        const date = currentPhoto?.created_at;
        const eventId = currentPhoto?.event
            ? currentPhoto.event.id
            : 0;

        if (date) {
            const time = moment(date).format('HH:mm:ss');
            const [h, m, s] = time.split(':');
            const d = new Date();

            d.setHours(Number(h), Number(m) - 1, Number(s), 0);

            const timeFrom = moment(d).format('HH:mm:ss');

            d.setHours(Number(h), Number(m) + 1, Number(s), 0);

            const timeTo = moment(d).format('HH:mm:ss');

            return (
                <div className={cn('person-photo__info-item')}>
                    <span>Время</span>
                    <Link
                        to={`/events/${eventId}?time_from=${timeFrom}&time_to=${timeTo}`}
                        className={cn('person-photo__info-item-link')}
                    >
                        {time}
                    </Link>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elPhotoColors = useMemo(() => {
        const colors = currentPhoto?.colors;
        const eventId = currentPhoto?.event
            ? currentPhoto.event.id
            : 0;

        if (Array.isArray(colors) && colors.length) {
            return (
                <div className={cn('person-photo__info-item')}>
                    <span>Цвета</span>
                    <div className={cn('person-photo__colors')}>
                        {
                            colors.map((item) => {
                                const color = getColor(item.id);
                                const link = `/events/${eventId}?color_ids=${item.id}`;

                                return (
                                    <a href={link} target="_blank" key={item.id}>
                                        <div
                                            className={
                                                cn('person-photo__colors-item', `person-photo__colors-item-${color}`)
                                            }
                                        />
                                    </a>
                                );
                            })
                        }
                    </div>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elPhotoNumbers = useMemo(() => {
        const numbers = currentPhoto?.numbers;
        const eventId = currentPhoto?.event
            ? currentPhoto.event.id
            : 0;

        if (Array.isArray(numbers) && numbers.length) {
            return (
                <div className={cn('person-photo__info-item')}>
                    <span>Номера</span>
                    <div className={cn('person-photo__numbers')}>
                        {
                            numbers.map((item) => {
                                const link = `/events/${eventId}?number=${item}`;

                                return (
                                    <a href={link} target="_blank" key={item}>
                                        <div
                                            className={cn(
                                                'person-photo__numbers-item',
                                                'person-photo__info-item-link'
                                            )}
                                        >
                                            {item}
                                        </div>
                                    </a>
                                );
                            })
                        }
                    </div>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elPhotoInfo = useMemo(() => {
        if (currentPhoto) {
            return (
                <UI.Box padding={true} className={cn('person-photo__info')}>
                    <UI.BoxHeader>О снимке</UI.BoxHeader>
                    <div className={cn('person-photo__info-item')}>
                        <span>Разрешение</span>
                        <span>{`${currentPhoto.original_file_width}x${currentPhoto.original_file_height}`}</span>
                    </div>
                    {currentPhoto?.original_file_size && (
                        <div className={cn('person-photo__info-item')}>
                            <span>Размер</span>
                            <span>{`${bytesToMegaBytes(currentPhoto?.original_file_size)} Мб`}</span>
                        </div>
                    )}
                    {currentPhoto?.photographer?.id && (
                        <div className={cn('person-photo__info-item')}>
                            <span>Фотограф</span>
                            <Link
                                to={`/photographer/${currentPhoto.photographer.id}`}
                                className={cn('person-photo__info-item-link')}
                            >
                                {`${currentPhoto.photographer.first_name} ${currentPhoto.photographer.last_name}`}
                            </Link>
                        </div>
                    )}
                    {elPhotoTime}
                    {elPhotoColors}
                    {elPhotoNumbers}
                </UI.Box>
            );
        }
    }, [JSON.stringify(currentPhoto)]);

    const elBreadcrumbs = useMemo(() => {
        if (currentPhoto && currentPhoto.event && isShowBreadcrumbs) {
            return <Breadcrumbs event={currentPhoto.event} photo={currentPhoto.id} />;
        }
    }, [currentPhoto, isShowBreadcrumbs]);

    const elPersonBlock = useMemo(() => {
        if (personList.length) {
            return (
                <div className={cn('person-photo__sidebar-container')}>
                    <UI.Box padding={true} className={cn('person-photo__sidebar-box')}>
                        <PersonList>
                            {personList.map((item) => {

                                    return (
                                        <PersonPhotoItem key={item.id} event={currentPhoto?.event} item={item} id={Number(photoId)} />
                                    );
                            })}
                        </PersonList>
                    </UI.Box>
                </div>
            );
        }
    }, [JSON.stringify(personList), currentPhoto]);

    const elHeader = useMemo(() => {
        if (currentPhoto && currentPhoto.event) {
            return (
                <div className={cn('person-photo__header')}>
                    <UI.BoxHeader>
                        {currentPhoto.event.name}
                    </UI.BoxHeader>
                </div>
            );
        }
    }, [JSON.stringify(currentPhoto), personList]);

    const onPhotoClick = (photo_id: number) => {
        history.replace({
            pathname: `/invited-albums/${albumId}/${photo_id}`,
            search: stringify(queryParams, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });

        api.photos.getPhotoItem(photo_id)
            .then((resp) => {
                setCurrentPhoto(resp.data);
            });
    };

    const onClickPrev = () => {
        if (prevPage) {
            setIsLoadingPrev(true);
            api.photos.getPhotosList(
                {
                    pageNumber: prevPage,
                    pageSize: PHOTOS_LIMIT
                }, {
                    person_id: [queryParams.person_id]
                })
                .then((resp) => {
                    const newPhotoList = [...resp.data.results, ...photoList];
                    setPrevPage(resp.data.previous ? resp.data.previous : null);
                    setPhotoList(newPhotoList);
                })
                .finally(() => setIsLoadingPrev(false));
        }
    };

    const onClickNext = () => {
        if (nextPage) {
            setIsLoadingNext(true);
            api.photos.getPhotosList(
                {
                    pageNumber: nextPage,
                    pageSize: PHOTOS_LIMIT
                }, {
                    person_id: [queryParams.person_id]
                })
                .then((resp) => {
                    setPhotoList((prev) => [...prev, ...resp.data.results]);
                    setNextPage(resp.data.next ? resp.data.next : null);
                })
                .finally(() => setIsLoadingNext(false));
        }
    };

    const elContent = useMemo(() => {
        if (!isLoading && currentPhoto && photoList.length && photoTotal && currentPhotoIndex !== undefined) {
            return (
                <UI.Box padding={true}>
                    {elHeader}
                        <PhotoCarousel
                            onClickPrev={onClickPrev}
                            onClickNext={onClickNext}
                            onPhotoClick={onPhotoClick}
                            currentPhotoIndex={currentPhotoIndex}
                            total={photoTotal}
                            list={photoList}
                            status={photoStatus?.payment_status}
                            isLoadingPrev={isLoadingPrev}
                            isLoadingNext={isLoadingNext}
                            startPage={startPage}
                            nextPage={nextPage}
                            prevPage={prevPage}
                            refObjectNext={$rightNextPhotos}
                            is_invited_album={true}
                        />
                </UI.Box>
            );
        }
    }, [currentPhoto, currentPhotoIndex, photoStatus, prevPage, nextPage, photoList, photoTotal, isLoadingPrev, isLoadingNext, isLoading, personList]);

    return (
        <>
            {elBreadcrumbs}
            <UI.Main className={cn('person-photo')}>
                <UI.Content className={cn('person-photo__content')}>
                    {elMessagesSubscription}
                    {elContent}
                </UI.Content>
                <UI.Sidebar className={cn('person-photo__sidebar')}>
                    {elSellBlock}
                    {elPhotoInfo}
                    {elPersonBlock}
                    {/* {elBanner} */}
                </UI.Sidebar>
            </UI.Main>
        </>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Photo;
