import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import { AccountsAPIType, GetAccountAdsListData, PremiumAccountRetrieve } from './types';
import { Page, PaginationResponse } from '../base';
import { AdItem } from '../ads/types';
import queryString from 'query-string';

const getAccountAdList = (page: Page, data?: GetAccountAdsListData): Promise<AxiosResponse<PaginationResponse<AdItem>>> => {
    return httpClient.get<PaginationResponse<AdItem>>('/api/v2/accounts/ads/',
        {
            params: {
                page: page.pageNumber,
                ...({ page_size: page.pageSize ? page.pageSize : 40 }),
                ...data
            },
            paramsSerializer(params) {
                return queryString.stringify(params, {arrayFormat: 'none'});
            }
        }
    );
};

const getAccountRetrieve = (): Promise<AxiosResponse> => {
    return httpClient.get('/api/v2/accounts/me/');
};

const getPremiumAccountRetrieve = (): Promise<AxiosResponse<PremiumAccountRetrieve>> => {
    return httpClient.get('/api/v2/accounts/premium-accounts/');
};

export default {
    getAccountAdList,
    getAccountRetrieve,
    getPremiumAccountRetrieve
} as AccountsAPIType;
