import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faShoppingCart, faUser } from '@fortawesome/free-solid-svg-icons';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import { TActiveState } from 'component/header/types';
import Burger from 'component/icon/burger';
import Close from 'component/icon/close';
import Logo from 'component/logo';

import { IStore } from 'store/reducers/types/reducers';
import { IStore as IStorePremium } from 'store/reducers/premium/types/reducer';
import { key as keyUser } from 'store/reducers/user/reducer';
import { key as deviceKey } from 'store/reducers/deviceInfo/reducer';
import { key as cartKey } from 'store/reducers/cart/reducer';
import { key as premiumKey } from 'store/reducers/premium/reducer';
import { key as taskKey } from 'store/reducers/task/reducer';
import { addCart } from 'store/reducers/cart/actions';

import UserMenu from './menu';
import style from './index.pcss';
import { useCancelToken } from 'component/core/cancel-token';
import axios from 'axios';
import api from 'src/api';
import { CartItemsStore } from 'src/store/reducers/cart/types/reducer';
import { PremiumAccountRetrieve } from 'src/api/accounts/types';
import { resetPremium, setPremium } from 'src/store/reducers/premium/actions';
import { resetTask, setTask } from 'src/store/reducers/task/actions';
import DownloadModal from 'component/download-modal';
import { Page } from 'src/api/base';
import { YandexTasksListData } from 'src/api/tasks/types';
import { CartItem } from 'src/api/cart/types';

const Header = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const $nav = useRef<HTMLElement>(null);
    const dispatch = useDispatch();

    const [activeState, setActiveState] = useState<TActiveState>(null);

    const isTablet = useSelector<IStore, boolean>((store) => store[deviceKey].tablet);
    const isMobile = useSelector<IStore, boolean>((store) => store[deviceKey].mobile);
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);
    const userEmail = useSelector<IStore, string | undefined>((store) => store[keyUser].email);
    const cartLength = useSelector<IStore, number | undefined>((store) => store[cartKey].count);
    const isPremium = useSelector<IStore, IStorePremium>((store) => store[premiumKey]);
    const isActiveTasks = useSelector<IStore, boolean | undefined>((store) => !!store[taskKey].count);

    const [cartItemsListPage, setCartItemsListPage] = useState<number>(1);
    const [isCartItemsListLoading, setIsCartItemsListLoading] = useState<boolean>(false);
    const [isCartItemsListLoadMore, setIsCartItemsListLoadMore] = useState<boolean>(false);
    const [cartItemsList, setCartItemsList] = useState<Array<CartItem>>([]);

    useEffect(() => {
        if (isCartItemsListLoading || isCartItemsListLoadMore) {
            api.cart.getCartItemsList({
                pageNumber: cartItemsListPage
            })
            .then((resp) => {
                const filteredList: CartItemsStore = {
                    count: 0,
                    items: []
                };

                setCartItemsList((prev) => isCartItemsListLoadMore ?
                    [...prev, ...resp.data.results] : resp.data.results);
                if (!resp.data.next) {
                    const newList = isCartItemsListLoadMore ? [...cartItemsList, ...resp.data.results].map((item) => filteredList.items.push(item))
                        : resp.data.results.map((item) => filteredList.items.push(item));
                    filteredList.count = resp.data.count;
                    dispatch(addCart(filteredList));
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);
                } else {
                    setIsCartItemsListLoading(false);
                    setIsCartItemsListLoadMore(false);

                    setCartItemsListPage((prev) => prev + 1);
                    setIsCartItemsListLoadMore(true);
                }
            });
        }
    }, [isCartItemsListLoading, isCartItemsListLoadMore]);

    const _requestUserCart = () => {
        setCartItemsListPage(1);
        setIsCartItemsListLoading(true);
    };

    const _requestPremiumAccount = () => {
        api.accounts.getPremiumAccountRetrieve()
            .then((resp) => {
                dispatch(setPremium(resp.data));
            })
            .catch(() => {
                console.error('Not premium account');
                dispatch(resetPremium());
            });
    };

    const _requestTasksList = () => {
        const page: Page = {
            pageNumber: 1
        };

        const filter: YandexTasksListData = {
            seen: false
        };

        api.tasks.getYandexTasksList(page, filter)
            .then((resp) => {
                dispatch(setTask(resp.data.count));
            })
            .catch(() => {
                console.error('No active download tasks');
                dispatch(resetTask());
            });
    };

    useEffect(() => {
        if (isAuth && localStorage.getItem('jwt_token')) {
            _requestUserCart();
            _requestPremiumAccount();
            _requestTasksList();
        }
    }, [isAuth]);

    const onOutsideClickUserMenu = useCallback((e) => {
        if(e) {
            const path = e.path || e.composedPath();

            if(Array.isArray(path)) {
                for(const item of path) {
                    if(item.classList && item.classList.contains(cn('header__user-block'))) {
                        return void 0;
                    }
                }
            }
        }

        setActiveState(null);
    }, []);

    const onClickActiveState = (newState: TActiveState) => () => {
        setActiveState((state) => (state === newState ? null : newState));
    };

    const elBurgerMenu = useMemo(() => {
        if(isTablet || isMobile) {
            const attributes = {
                className: cn('header__burger-menu'),
                onClick  : onClickActiveState('navigation')
            };

            if(activeState === 'navigation') {
                return <Close {...attributes} />;
            }

            return <Burger className={attributes.className} onClick={onClickActiveState('navigation')} />;
        }
    }, [activeState, isTablet, isMobile]);

    useEffect(() => {
        if(activeState && (isTablet || isMobile)) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.removeAttribute('style');
        }
    }, [activeState, isTablet, isMobile]);

    const elUserMenu = useMemo(() => {
        return (
            <CSSTransition
                in={activeState === 'profile'}
                timeout={500}
                unmountOnExit={true}
                mountOnEnter={true}
                classNames={{
                    enter: cn('header__modal_enter'),
                    exit: cn('header__modal_exit')
                }}
            >
                <UserMenu
                    className={cn('header__modal')}
                    onClickLogout={onClickActiveState(null)}
                    onClickItem={onClickActiveState(null)}
                    onOutsideClick={onOutsideClickUserMenu}
                />
            </CSSTransition>
        );
    }, [activeState]);

    const elNav = useMemo(() => {
        const items = [
            {
            to: '/search',
            name: 'search'
            },
            {
                to: '/subscriptions',
                name: 'subscriptions'
            }
        ];

        return (
            <nav
                ref={$nav}
                className={cn('header__nav', {
                    'header__nav_mobile': isMobile || isTablet,
                    'header__modal'     : isMobile || isTablet
                })}
            >
                {items.map((item, index) => (
                    <NavLink
                        key={index}
                        to={item.to}
                        className={cn('header__nav-link')}
                        onClick={onClickActiveState(null)}
                    >
                        {t(`components.header.menu.${item.name}`)}
                    </NavLink>
                ))}
            </nav>
        );
    }, [activeState, isMobile, isTablet]);

    const elMobileMenu = useMemo(() => {
        return (
            <CSSTransition
                in={(isMobile || isTablet) && activeState === 'navigation'}
                timeout={500}
                unmountOnExit={true}
                mountOnEnter={true}
                classNames={{
                    enter: cn('header__modal_enter'),
                    exit: cn('header__modal_exit')
                }}
            >
                {elNav}
            </CSSTransition>
        );
    }, [activeState, isMobile, isTablet]);

    const elUser = useMemo(() => {
        if(userId && isAuth) {
            return (
                <div className={cn('header__user-menu')}>
                    <div className={cn('header__user-block')} onClick={onClickActiveState('profile')}>
                        {<FontAwesomeIcon icon={faUser} className={cn('header-icon')} />}
                        <span
                            className={cn('header__user-name', {
                                'header__user-name_active': activeState === 'profile'
                            })}
                        />
                    </div>
                    <Link to="/cart" className={cn('header__cart')} data-count={cartLength}>
                        <FontAwesomeIcon icon={faShoppingCart} className={cn('header-icon')} />
                    </Link>
                </div>
            );
        } else {
            const sign_in = <FontAwesomeIcon icon={faSignInAlt} className={cn('header-icon')} />;

            return (
                <div className={cn('header__user-menu')}>
                    <Link to="/registration">
                        {(isTablet || isMobile) ? sign_in : t('components.header.login')}
                    </Link>
                    <Link to="/cart" className={cn('header__cart')} data-count={cartLength}>
                        <FontAwesomeIcon icon={faShoppingCart} className={cn('header-icon')} />
                    </Link>
                </div>
            );
        }
    }, [userId, activeState, cartLength, isTablet, isMobile, isAuth, isPremium]);

    const elDownloadModal = useMemo(() => {
        if (isActiveTasks) {
            return <DownloadModal />;
        }
    }, [isActiveTasks]);

    return (
        <header className={cn('header')}>
            <UI.Main className={cn('header__wrapper')}>
                <div className={cn('header__content')}>
                    <Link to="/" className={cn('header__title')}>
                        <Logo
                            className={cn('header__logo')}
                            viewBox="0 0 140 48"
                            width={140}
                        />
                    </Link>
                    {elBurgerMenu}
                    {!(isMobile || isTablet) && elNav}
                    <div className={cn('header__user')}>
                        {elUser}
                    </div>
                </div>
                {elUserMenu}
                {elMobileMenu}
                {elDownloadModal}
            </UI.Main>
        </header>
    );
};

export default Header;
