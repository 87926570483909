import React, { useState, useEffect, useRef, useMemo, FC, MouseEvent, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { useClassnames } from 'hook/use-classnames';
import qaAttributes from 'component/helper/qa-attributes';
import ImagesIcon from 'component/icon/images';
import DownloadIcon from 'component/icon/download';
import Loader from 'component/loader';
import Error from 'component/error';
import Button from 'component/button';
import cancelToken, { IReturnInterface } from 'component/core/cancel-token';
import PhotoList from 'component/photo-list';
import Status from 'route/upload/status';
import ModalExternal from './external';
import ModalMessageSetPhone from 'component/modal/message-set-phone';

import { Data as CreateData } from 'component/api/types/api/photo/photo/create/post/code-200';
import { tmpFile } from 'component/api/tmp-file';
import { createPhoto } from 'component/api/photo';
import Presets from './presets';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

import { IError, ILoadingStatus, IProps, TError, ICreateDataUpload, IDefaultPresets, IDefaultPresetsItem } from './types';
import style from './index.pcss';
import ModalExternalLink from './external-link';

const ERROR_CLEAR_TIMER = 10000;

const InputFile: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const tokens = useRef<Array<IReturnInterface>>([]);
    const tokensCreate = useRef<Array<IReturnInterface>>([]);

    const userPhone = useSelector<IStore, string | null>((store) => store[keyUser].fps_phone_number || null);
    const moderationPhone = useSelector<IStore, string | null>((store) => store[keyUser]?.moderation_user?.fps_phone_number || null);

    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [value, setValue] = useState<Array<CreateData>>([]);
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [errorRequest, setErrorRequest] = useState<TError>(null);
    const [isWatch, setIsWatch] = useState<boolean>(!!props.defaultValue);
    const [firstClick, setFirstClick] = useState<boolean>(false);
    const [canStartLoading, setCanStartLoading] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [createPending, setCreatePending] = useState<boolean>(false);
    const [size, setSize] = useState<number>(0);
    const [sizesArray, setSizesArray] = useState<Array<number>>([]);
    const [formDataArray, setFormDataArray] = useState<Array<FormData>>([]);
    const [loadingStatus, setLoadingStatus] = useState<Array<ILoadingStatus>>([]);
    const [total, setTotal] = useState<number>(0);
    const [loaded, setLoaded] = useState<number>(0);
    const [loadedSize, setLoadedSize] = useState<number>(0);
    const [errorIndex, setErrorIndex] = useState<IError>({});
    const [showModal, setShowModal] = useState<boolean>(false);
    const [service, setService] = useState<string>('');
    const [modalPrice, setModalPrice] = useState<boolean>(false);
    const [showLinkModal, setShowLinkModal] = useState<boolean>(false);

    const onDrop = useCallback((files): void => {
        if(errorExternal) {
            setErrorExternal(null);
        }

        if(errorRequest) {
            setErrorRequest(null);
        }

        if(Object.values(errorIndex)) {
            setErrorIndex({});
        }

        setPending(true);

        const newFormDataArray: Array<FormData> = [];

        if(files) {
            const sizes: Array<number> = [];

            // tslint:disable-next-line prefer-for-of
            for(let i = 0; i < files.length; i++) {
                const formData = new FormData();
                const maxSizeValue = props.maxSize ? files[i].size <= props.maxSize * 1024 * 1024 : true;
                const maxCountValue = true;

                if(maxSizeValue) {
                    if(maxCountValue) {
                        sizes.push(files[i].size);

                        formData.append('name', files[i].name);
                        formData.append('file', files[i]);
                        formData.append('basket', 'PHOTO');

                        newFormDataArray.push(formData);
                    } else {
                        setErrorRequest(t('components.form.input-file.error-max-count', {
                            maxCount: props.maxCount
                        }));
                    }
                } else {
                    const newErrorRequest = t('components.form.input-file.error-max-size', {
                        name: files[i].name
                    });

                    setErrorRequest(newErrorRequest);
                }

                if(i + 1 === files.length) {
                    setPending(false);
                }
            }

            const newSize = sizes.reduce((accumulator: number, current: number) => accumulator + current, 0);

            setSize(newSize);
            setSizesArray(sizes);
            refSizes.current = sizes;
        }

        if(newFormDataArray.length) {
            setCanStartLoading(true);
            setFormDataArray(newFormDataArray);
        }
    }, [value]);

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    useEffect(() => {
        const newTotal = loadingStatus.reduce((acc, curr) => {
            return parseInt((acc + curr.status).toFixed(0), 10);
        }, 0) / loadingStatus.length;

        setTotal(newTotal);
    }, [JSON.stringify(loadingStatus)]);

    useEffect(() => {
        const newLoadingStatus = acceptedFiles.map((item) => {
            return {
                name: item.name,
                status: 0
            };
        });

        setLoadingStatus(newLoadingStatus);
    }, [acceptedFiles]);

    const firstClickRef = useRef<boolean>(firstClick);
    const isWatchRef = useRef<boolean>(isWatch);
    const refValue = useRef<Array<CreateData>>(value);
    const refSizes = useRef<Array<number>>(sizesArray);
    const $label = useRef<HTMLLabelElement>(null);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if(props.required) {
            newIsValid = !!value.length;

            if(!newIsValid) {
                newErrorInternal = t('components.form.input-file.error');
            }
        }

        if(newIsValid && errorRequest) {
            newErrorInternal = errorRequest;
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    const onWatch = (): void => {
        if(firstClickRef.current && !isWatch) {
            const watch = document.hasFocus();

            if(watch) {
                setIsWatch(watch);
            }
        }
    };

    useEffect(() => {
        if(props.defaultValue) {
            setValue(props.defaultValue);
        }
    }, [JSON.stringify(props.defaultValue)]);

    useEffect(() => {
        checkValidity();
    }, [isWatch, errorRequest]);

    useEffect(() => {
        props.registryField.set(props.name, {
            value,
            setError  : setErrorExternal,
            isAutoFill: false,
            clear     : () => {
                setValue([]);
                setIsWatch(false);
            },
            isValid: checkValidity()
        });

        const handler = props.registryField.onChange();

        if(handler) {
            handler();
        }

        refValue.current = value;
    }, [JSON.stringify(value)]);

    useEffect(() => {
        firstClickRef.current = firstClick;
        isWatchRef.current = isWatch;
    }, [firstClick, isWatch]);

    useEffect(() => {
        window.addEventListener('blur', onWatch);
        window.addEventListener('focus', onWatch);

        return () => {
            window.removeEventListener('blur', onWatch);
            window.removeEventListener('focus', onWatch);

            props.registryField.remove(props.name);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setErrorRequest(null);
        }, ERROR_CLEAR_TIMER);

        return () => {
            clearTimeout(timer);
        };
    }, [errorRequest]);

    const formatSize = (bytes: number): string => {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];

        let l = 0;
        let n = bytes || 0;

        // tslint:disable-next-line increment-decrement
        while(n >= 1024 && ++l) {
            n = n / 1024;
        }

        return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
    };

    const uploadProgressSet = (totalProgress: number, loadedProgress: number, index: number, name: string) => {
        const percent = ((loadedProgress * 100 / totalProgress) * 0.7).toFixed(0);

        setLoadingStatus((oldState) => {
            const newState = [...oldState];

            newState[index] = {
                name,
                status: parseInt(percent, 10)
            };

            return newState;
        });
    };

    const getUploadPresets = () => {
        const fields = props.registry.form.getFields();

        return {
            year: fields && fields.get('upload_year') || null,
            event: fields && fields.get('upload_tournament') || null,
            price: fields && fields.get('upload_price') || null,
            album: fields && fields.get('upload_album') || null,
            is_save_params: fields && fields.get('save_params') || null
        };
    };

    const onClickSubmitFiles = (is_params: boolean) => (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        const presets = getUploadPresets();

        if (is_params) {
            let paramsValid = true;

            if (presets.year && presets.year.value?.value && !presets.event?.value?.value) {
                presets.event?.setError('Укажите событие');
                paramsValid = false;
            }

            if (presets.price && presets.price.value && (Number(presets.price?.value) < 20)) {
                presets.price.setError('Минимальная стоимость 20 руб.');
                paramsValid = false;
            }

            if (presets.album && !presets.album?.value?.value) {
                presets.album.setError('Укажите альбом');
                paramsValid = false;
            }

            if (!paramsValid && !props.isPhotoPreview) {
                return void(0);
            }

            if ((!userPhone || moderationPhone)
                && presets.price
                && presets.price.value
                || !userPhone
                || moderationPhone
            ) {
                setModalPrice(true);

                return void(0);
            }

            if (!!presets.is_save_params?.value && paramsValid && !props.isPhotoPreview) {
                const yearPreset: IDefaultPresetsItem = {};

                if (presets.year && presets.year.value && presets.year.value.value && presets.year.value.label) {
                    yearPreset.value = presets.year?.value.value;
                    yearPreset.label = presets.year?.value.label;
                }

                const eventPreset: IDefaultPresetsItem = {};

                if (presets.event && presets.event.value && presets.event.value.value && presets.event.value.label) {
                    eventPreset.value =  presets.event?.value.value;
                    eventPreset.label = presets.event?.value.label;
                }

                const albumPreset: IDefaultPresetsItem = {};

                if (presets.album && presets.album.value && presets.album.value.value && presets.album.value.label) {
                    albumPreset.value =  presets.album?.value.value;
                    albumPreset.label = presets.album?.value.label;
                }

                const defaultParams: IDefaultPresets = {
                    year: yearPreset.value && yearPreset.label ? yearPreset : {},
                    event: eventPreset.value && eventPreset.label ? eventPreset : {},
                    price: presets.price?.value ? presets.price?.value : '',
                    album: albumPreset.value && albumPreset.label ? albumPreset : {}
                };

                localStorage.setItem('upload_params', JSON.stringify(defaultParams));
            }
        }

        if (!presets.is_save_params?.value) {
            localStorage.removeItem('upload_params');
        }

        setCanStartLoading(false);
        setCreatePending(true);
        if (props.onClickStartLoading) {
            props.onClickStartLoading();
        }

        const newSizesArray = [...sizesArray];

        const promises: Array<Promise<CreateData>> = [];

        const promiseCreate = (item: FormData, index: number) => {
            return (): Promise<CreateData> => new Promise((resolve, reject) => {
                const initValueLength = value.length;
                const valueIndex = initValueLength + index;

                tokens.current[valueIndex] = cancelToken.create();

                tmpFile({
                    data       : item,
                    cancelToken: tokens.current[valueIndex].new(),
                    onUploadProgress: (progress) => {
                        uploadProgressSet(progress.total, progress.loaded, index, item.get('name') as string);
                    }
                })
                    .then((payload) => {
                        // tslint:disable-next-line no-dynamic-delete
                        delete refSizes.current[index];

                        newSizesArray.splice(index, 1);

                        const remainSize = refSizes.current.length > 1 ? refSizes.current.reduce((acc, curr) => acc + curr, 0) : size;
                        const newLoadedSize = size - remainSize;

                        setLoadedSize(newLoadedSize);

                        setLoaded((oldLoaded) => oldLoaded + 1);
                        setLoadingStatus((oldState) => {
                            const newState = [...oldState];
                            newState[index].status = parseInt((Math.random() * (90 - oldState[index].status) + oldState[index].status).toFixed(2), 10);

                            return newState;
                        });
                        tokens.current[valueIndex].remove();

                        // tslint:disable-next-line no-dynamic-delete
                        delete tokens.current[valueIndex];

                        tokensCreate.current[index] = cancelToken.create();

                        let dataCreatePhoto;

                        if (!props.isPhotoPreview) {
                            dataCreatePhoto = {
                                tmp_file_id: payload.id,
                                photo_data: {
                                    event_id: Number(presets.event?.value?.value),
                                    price: Number(presets.price?.value),
                                    album_id: Number(presets.album?.value?.value)
                                }
                            };
                        } else {
                            const uploadVideoPresets = localStorage.getItem('upload_video_params');
                            if (props.uploadingInfo) {
                                dataCreatePhoto = {
                                    tmp_file_id: payload.id,
                                    photo_data: {
                                        album_id: Number(props.uploadingInfo.album?.value),
                                        event_id: Number(props.uploadingInfo.event?.value),
                                        price: Number(presets.price?.value)
                                    }
                                };
                            } else if (props.isHasVideo) {
                                dataCreatePhoto = {
                                    tmp_file_id: payload.id,
                                    photo_data: {
                                        album_id: props.isHasVideo.album.id,
                                        event_id: props.isHasVideo.event.id,
                                        price: Number(presets.price?.value)
                                    }
                                };
                            } else if (props.isHasAlbum) {
                                dataCreatePhoto = {
                                    tmp_file_id: payload.id,
                                    photo_data: {
                                        album_id: props.isHasAlbum.id,
                                        event_id: Number(presets.event?.value),
                                        price: Number(presets.price?.value)
                                    }
                                };
                            } else if (uploadVideoPresets) {
                                dataCreatePhoto = {
                                    tmp_file_id: payload.id,
                                    photo_data: {
                                        event_id: JSON.parse(uploadVideoPresets).event.value,
                                        album_id: JSON.parse(uploadVideoPresets).album.value,
                                        price: Number(presets.price?.value)
                                    }
                                };
                            }
                        }

                        createPhoto({
                            data: dataCreatePhoto,
                            cancelToken: tokensCreate.current[index].new()
                        })
                            .then((response) => {
                                setLoadingStatus((oldState) => {
                                    const newState = [...oldState];

                                    newState[index].status = 100;

                                    return newState;
                                });

                                resolve(response);

                                tokensCreate.current[index].remove();
                                // tslint:disable-next-line no-dynamic-delete
                                delete tokensCreate.current[index];
                            })
                            .catch((error) => {
                                if(!axios.isCancel(error)) {
                                    console.error(error);

                                    setLoadingStatus((oldState) => {
                                        const newState = [...oldState];

                                        newState[index].status = 100;

                                        return newState;
                                    });

                                    setErrorIndex((oldState) => {
                                        const newErrors = { ...oldState };

                                        newErrors[index] = error.errors && error.errors[0]?.message || error.message || t('components.form.input-file.error-default');

                                        return newErrors;
                                    });

                                    reject(index);
                                }

                                tokensCreate.current[index].remove();

                                // tslint:disable-next-line no-dynamic-delete
                                delete tokensCreate.current[index];
                            });
                    })
                    .catch((err) => {
                        if(!axios.isCancel(err)) {
                            console.error(err);

                            setLoadingStatus((oldState) => {
                                const newState = [...oldState];

                                newState[index].status = 100;

                                return newState;
                            });

                            setErrorIndex((oldState) => {
                                const newErrors = { ...oldState };

                                newErrors[index] = err.errors && err.errors[0]?.message || err.message || t('components.form.input-file.error-default');

                                return newErrors;
                            });

                            reject(index);
                        }

                        tokens.current[valueIndex].remove();

                        // tslint:disable-next-line no-dynamic-delete
                        delete tokens.current[valueIndex];
                    });
            });
        };

        formDataArray.forEach((item, index) => {
            // @ts-ignore
            promises.push(promiseCreate(item, index));
        });

        // tslint:disable-next-line prefer-const
        let responses: Array<ICreateDataUpload> = [];
        const doAllPromises = async (promisesArr: Array<Promise<CreateData>>) => {
            for(const promiseItem of promisesArr) {
                // @ts-ignore
                await promiseItem()
                    .then((response: CreateData) => {
                        responses.push({
                            status: 'fulfilled',
                            response
                        });
                    })
                    // @ts-ignore
                    .catch((response) => {
                        responses.push({
                            status: 'rejected',
                            response
                        });
                    });
            }
        };

        doAllPromises(promises).then(() => {
            // @ts-ignore
            const success: Array<CreateData> = responses.map((item) => item.status === 'fulfilled' && item.response);
            const failed = responses.filter((item) => item.status === 'rejected').map((item) => item.response) || [];

            setValue(success);

            if(!failed.length) {
                setPending(false);
                refSizes.current = [];
                setSizesArray([]);
                setCreatePending(false);

                if(props.onFilesLoaded) {
                    props.onFilesLoaded(success);
                }
            }
        });
    };

    const onClick = (): void => {
        if(!firstClick) {
            setFirstClick(true);
        }
    };

    const elError = useMemo((): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('input__error')}>{errorInternal || errorExternal}</Error>;
        }
    }, [isWatch, errorInternal, errorExternal]);

    const elLabel = useMemo((): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('input__label', {
                        'input__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    }, [props.children, props.required]);

    const onClickLabel = (): void => {
        if($label?.current) {
            $label?.current.click();
        }
    };

    const onClickCancel = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        clearState();
    };

    const clearState = (): void => {
        setFormDataArray([]);
        setPending(false);
        setCreatePending(false);
        setValue([]);
        setSizesArray([]);
        setSize(0);
        setCanStartLoading(false);

        if(props.onCancelLoading) {
            props.onCancelLoading();
        }
    };

    const onClickCloseMessage = (): void => {
        setModalPrice(false);
    };

    const elModalMessageSetPhone = useMemo(() => {
        if (modalPrice) {
            return <ModalMessageSetPhone onClickClose={onClickCloseMessage} />;
        }
    }, [modalPrice]);

    const onClickUploadDisk = useCallback((serv: string) => (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setService(serv);
        setShowModal(true);
    }, []);

    const onClickUploadLink = useCallback(() => (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setShowLinkModal(true);
    }, []);

    const onCloseModal = useCallback(() => {
        setShowModal(false);
        setShowLinkModal(false);
    }, []);

    const elPlaceholder = useMemo((): ReactNode => {
        return (
            <>
                <span
                    className={cn('input__placeholder', {
                        'input__placeholder_invalid': (isWatch && errorInternal) || errorExternal
                    })}
                >
                    {t('components.form.input-file.placeholder', {
                        context: createPending ? 'uploading' : formDataArray.length ? 'images' : 'default',
                        count  : formDataArray.length,
                        size   : formatSize(size)
                    })}
                    {formDataArray.length ? null : (
                        <>
                            <br />
                            <br />
                            {t('route.upload.sidebar.empty.image')}
                        </>
                    )}
                    {props.isPhotoPreview && (
                        <>
                            <br />
                            {t('components.form.input-file.placeholder_limit', {
                                count: 10
                            })}
                        </>
                    )}
                </span>
            </>
        );
    }, [isWatch, errorInternal, errorExternal, size, createPending, formDataArray.length]);

    const elPlaceholderDisk = useMemo((): ReactNode => {
        if (props.external) {
            return (
                <span className={cn('input__placeholder')}>
                    ...или загрузите с
                    <span className={cn('input__placeholder-pointer')} onClick={onClickUploadDisk('yandex')}>Яндекс.Диска</span>
                </span>
            );
        }
    }, [props.external]);

    const elPlaceholderLink = useMemo((): ReactNode => {
        if (props.external) {
            return (
                <>
                    <span className={cn('input__placeholder')}>
                        <span className={cn('input__placeholder-new')}>New*</span> Вставьте ссылку на
                        <span className={cn('input__placeholder-pointer')} onClick={onClickUploadLink()}>Яндекс.Диск</span>
                    </span>
                </>
            );
        }
    }, [props.external]);

    const elModalUploadDisk = useMemo(() => {
        if (showModal) {
            return (
                <ModalExternal
                    service={service}
                    onCloseModal={onCloseModal}
                />
            );
        }
    }, [showModal, service]);

    const elModalUploadLink = useMemo(() => {
        if (showLinkModal) {
            return (
                <ModalExternalLink onCloseModal={onCloseModal} />
            );
        }
    }, [showLinkModal]);

    const elPlaceholderImage = useMemo(() => {
        if(formDataArray.length) {
            return <ImagesIcon className={cn('input__icon', 'input__icon_images')} />;
        }

        return <DownloadIcon className={cn('input__icon')} />;
    }, [formDataArray.length]);

    const onStopLoading = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        tokens.current.forEach((token) => {
            token.remove();
        });
        tokensCreate.current.forEach((token) => {
            token.remove();
        });

        clearState();
    };

    const elPlaceholderButtons = useMemo(() => {
        if(formDataArray.length) {
            return (
                <div className={cn('input__buttons')}>
                    <Button
                        type="button"
                        className={cn('input__upload-button', 'input__upload-button_two-line')}
                        onClick={onClickSubmitFiles(true)}
                        isSmall={true}
                    >
                        {t('components.form.input-file.button-start-params')}
                    </Button>
                    {/* <Button
                        type="button"
                        className={cn('input__upload-button', 'input__upload-button_two-line')}
                        onClick={onClickSubmitFiles(false)}
                        isSecondary={true}
                        isSmall={true}
                    >
                        {t('components.form.input-file.button-start')}
                    </Button> */}
                    <Button
                        type="button"
                        isSecondary={true}
                        className={cn('input__upload-button', 'input__upload-button_secondary', 'input__upload-button_two-line')}
                        onClick={onClickCancel}
                        isSmall={true}
                    >
                        {t('components.form.input-file.button-reset')}
                    </Button>
                </div>
            );
        }

        return (
            <Button
                type="button"
                className={cn('input__upload-button')}
                onClick={onClickLabel}
                isSmall={true}
            >
                {t('components.form.input-file.button')}
            </Button>
        );
    }, [formDataArray.length, createPending]);

    const elPreviewPlaceholder = (): ReactNode => {
        const className = cn('input__preview-content', {
            'input__preview-content_default'            : canStartLoading,
            'input__preview-content_placeholder-invalid': (isWatch && errorInternal) || errorExternal
        });

        if(createPending) {
            return <PhotoList disableButtons={true} className={cn('input__list')} list={[]} loadingList={loadingStatus} errors={errorIndex} />;
        }

        return (
            <div className={className}>
                {elPlaceholderImage}
                {elPlaceholder}
                {elPlaceholderButtons}
                {!props.isPhotoPreview && !props.isVideoUploaded && (
                    <>
                        {elPlaceholderDisk}
                        {elPlaceholderLink}
                    </>
                )}
            </div>
        );
    };

    const elLoader = useMemo(() => {
        if(pending) {
            return <Loader className={cn('input__loader')} />;
        }
    }, [pending]);

    const onClickResume = useCallback(() => {
        if(props.onFilesLoaded) {
            props.onFilesLoaded(value);
        }
    }, [JSON.stringify(value)]);

    const elResumeButton = useMemo(() => {
        const errorsLength = Object.values(errorIndex).length;

        if(errorsLength && value.length > errorsLength) {
            return (
                <Button
                    type="button"
                    className={cn('input__stop-button')}
                    onClick={onClickResume}
                >
                    {t('components.form.input-file.button-resume')}
                </Button>
            );
        }
    }, [JSON.stringify(errorIndex), JSON.stringify(value)]);

    const elSidebar = useMemo(() => {
        if(acceptedFiles.length && createPending) {
            const errLength = errorIndex ? Object.keys(errorIndex).length : 0;
            const queue = acceptedFiles.length - loaded - errLength;
            const isAllErrors = acceptedFiles.length === errLength;

            return (
                <div
                    className={cn('input__sidebar', {
                        'input__sidebar-video': props.isPhotoPreview
                    })}
                >
                    <h3 className={cn('input__page-header')}>{t('route.upload.sidebar.uploading.header')}</h3>
                    <Status progress={total} isDone={true} />
                    <div className={cn('input__sidebar-items')}>
                        {queue > 0 && (
                            <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                                <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.remain')}</span>
                                <div className={cn('input__sidebar-sub')}>
                                <span className={cn('input__sidebar-count')}>
                                    {t('route.upload.sidebar.uploading.remain-time', { count: queue })}
                                </span>
                                </div>
                            </div>
                        )}
                        <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                            <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.total')}</span>
                            <div className={cn('input__sidebar-sub')}>
                                <span className={cn('input__sidebar-count')}>
                                    {t('route.upload.sidebar.uploading.total-count', { count: acceptedFiles.length })}
                                </span>
                                <span className={cn('input__sidebar-sub-text')}>
                                    {t('route.upload.sidebar.uploading.total-size', { size: formatSize(size) })}
                                </span>
                            </div>
                        </div>
                        <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                            <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.uploaded')}</span>
                            <div className={cn('input__sidebar-sub')}>
                                <span className={cn('input__sidebar-count')}>
                                    {t('route.upload.sidebar.uploading.uploaded-remain', { loaded })}
                                </span>
                                <span className={cn('input__sidebar-sub-text')}>
                                    {t('route.upload.sidebar.uploading.uploaded-size', { size: formatSize(loadedSize) })}
                                </span>
                            </div>
                        </div>
                        <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                            <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.queue')}</span>
                            <div className={cn('input__sidebar-sub')}>
                                <span className={cn('input__sidebar-count')}>
                                    {t('route.upload.sidebar.uploading.queue-remain', { count: queue })}
                                </span>
                                <span className={cn('input__sidebar-sub-text')}>
                                    {t('route.upload.sidebar.uploading.queue-size', { size: queue === 0 ? 0 : formatSize(size - loadedSize) })}
                                </span>
                            </div>
                        </div>
                        <div className={cn('input__sidebar-item', 'input__sidebar-item_border')}>
                            <span className={cn('input__sidebar-text')}>{t('route.upload.sidebar.uploading.errors')}</span>
                            <div className={cn('input__sidebar-text')}>{errLength}</div>
                        </div>
                        {elResumeButton}
                        <Button
                            type="button"
                            className={cn('input__stop-button', 'input__stop-button_secondary')}
                            isSecondary={true}
                            onClick={onStopLoading}
                        >
                            {t('components.form.input-file.button-pause', { context: isAllErrors ? 'errors' : 'default' })}
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <Presets
                    registry={props.registry}
                    isSaveParams={props.isSaveParams}
                    isPhotoPreview={props.isPhotoPreview}
                    isHasAlbum={props.isHasAlbum}
                    isHasVideo={props.isHasVideo}
                    uploadingInfo={props.uploadingInfo}
                />
            </div>
        );
    }, [JSON.stringify(acceptedFiles), total, loaded, size, loadedSize, createPending, JSON.stringify(errorIndex), JSON.stringify(value), JSON.stringify(errorIndex)]);

    const elInput = () => {
        return (
            <div className={cn('input__group')}>
                <div
                    {...getRootProps({
                        className: cn('input__wrapper', {
                            'input__wrapper_active'  : isDragActive,
                            'input__wrapper_disabled': props.disabled
                        })
                    })}
                >
                    {elPreviewPlaceholder()}
                    <input
                        {...qaAttributes(props['data-qa'] ? `file:${props['data-qa']}` : 'file')}
                        {...getInputProps({
                            accept   : props.accept,
                            name     : props.name,
                            id       : props.id || props.name,
                            multiple : true,
                            type     : 'file',
                            disabled : props.disabled || pending || value.length >= 20 || !!value.length || createPending || canStartLoading,
                            required : props.required,
                            value    : '',
                            title    : '',
                            tabIndex : props.tabIndex,
                            className: cn('input__field'),
                            onClick
                        })}
                    />
                </div>
            </div>
        );
    };

    return (
        <div
            className={cn('input', {
            'input__video': props.isPhotoPreview && !props.isStartLoadingVideoPhotos
            })}
        >
            <div className={cn('input__content')}>
                <div className={cn('input__field-wrapper')}>
                    {elModalMessageSetPhone}
                    {!props.isPhotoPreview && !props.isVideoUploaded && (
                        <>
                            {elModalUploadDisk}
                            {elModalUploadLink}
                        </>
                    )}
                    {elLoader}
                    {elLabel}
                    {elInput()}
                    {elError}
                </div>
            </div>
            {elSidebar}
        </div>
    );
};

InputFile.defaultProps = {
    direction: 'row',
    accept   : '.jpg,.jpeg,.png,.cr2,.raw',
    maxSize  : 50,
    maxCount : 100
};

// tslint:disable-next-line max-file-line-count
export default InputFile;
