import { createReducer } from '@reduxjs/toolkit';

import { setPremium, resetPremium } from './actions';
import { IStore } from './types/reducer';

export const key = 'premium' as const;

export default createReducer<IStore>({}, (builder) => {
    builder
        .addCase(setPremium, (store, action) => {
            if(action.payload) {
                return {
                    ...store,
                    ...action.payload
                };
            }

            return store;
        })
        .addCase(resetPremium, () => {
            sessionStorage.removeItem('premium');

            return {};
        });
});
