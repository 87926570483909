import React, { FC, useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import uniqBy from 'lodash.uniqby';

import { useCancelToken } from 'component/core/cancel-token';
import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import Button from 'component/button';
import InputFile from 'component/form/input-file';
import Form, { useRegistry } from 'component/form';
import PhotoList from 'component/photo-list';
import sortPhotoArray, { ISortedMap } from 'component/helper/sort-photos-list';

import { bulkEditPhoto } from 'component/api/photo';
import { Data as CreateData } from 'component/api/types/api/photo/photo/create/post/code-200';

import style from './style.pcss';
import SidebarActions from 'component/sidebar-actions';
import { ISelectedMap } from 'route/dashboard/photos/types';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';

const Upload: FC = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const tokenEdit = useCancelToken();
    const registry = useRegistry();
    const history = useHistory();
    const match = useRouteMatch<{ component: string }>('/:component');
    const [images, setImages] = useState<Array<CreateData>>([]);
    const [photosMap, setPhotosMap] = useState<ISortedMap | null>(null);
    const [idsList, setIdsList] = useState<ISelectedMap>({});

    const refIds = useRef<ISelectedMap>(idsList);
    const userId = useSelector<IStore, number | undefined>((store) => store[keyUser].id);

    if (!userId) {
        return <Redirect to={'/login'} />;
    }

    const onFilesLoaded = (newImages: Array<CreateData>): void => {
        setImages(newImages);
    };

    const onCancelLoading = (): void => {
        setImages([]);
        setIdsList([]);
    };

    useEffect(() => {
        if(!images) {
            history.replace('/upload-photo');
        } else {
            setPhotosMap(sortPhotoArray(images));
        }
    }, [JSON.stringify(images)]);

    useEffect(() => {
        refIds.current = idsList;
    }, [JSON.stringify(idsList)]);

    const onChangeSelectedList = useCallback((index: number) => (ids: Array<number>) => {
        const newList = { ...refIds.current };

        newList[index] = ids;

        setIdsList(newList);
    }, [idsList]);

    const updPhotoInfo = useCallback((photo_data) => {
        const keys = Object.keys(refIds.current);

        const list = keys.reduce((accum: Array<number>, current: string) => {
            return accum.concat(refIds.current[Number(current)]);
        }, []);

        bulkEditPhoto({
            cancelToken: tokenEdit.new(),
            data       : {
                photo_ids: list,
                photo_data
            }
        })
            .then((payload) => {
                setImages(uniqBy([...payload.photo_list, ...images], 'id'));
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);
                }
            });
    }, [JSON.stringify(images)]);

    const elSidebarActions = useMemo(() => {
        const keys = Object.keys(idsList);

        const listLength = keys.reduce((accum: number, key: string) => {
            return accum + idsList[Number(key)].length;
        }, 0);

        return (
            <SidebarActions
                update={updPhotoInfo}
                checkedCount={listLength}
            />
        );
    }, [JSON.stringify(idsList), JSON.stringify(images)]);

    const elList = useMemo(() => {
        if(photosMap) {
            const keys = Object.keys(photosMap);

            return keys.map((key, index) => {
                return <PhotoList disableButtons={true} photoOwner={true} key={index} list={photosMap[key]} onChangeSelectedList={onChangeSelectedList(index)} />;
            });
        }
    }, [JSON.stringify(photosMap)]);

    const elContent = () => {
        if (images.length) {
            return (
                <div className={cn('upload__grid')}>
                    <div className={cn('upload__content')}>
                        {elHeader}
                        {elList}
                    </div>
                    <UI.Sidebar className={cn('upload__sidebar')}>
                        <UI.Box padding={true}>
                            {elSidebarActions}
                        </UI.Box>
                        <UI.Box padding={true}>
                            <UI.BoxHeader>{t('route.upload.sidebar.done.title')}</UI.BoxHeader>
                            <p className={cn('upload__done-text')}>{t('route.upload.sidebar.done.text')}</p>
                            <Button className={cn('upload__done-button')} to="/dashboard/photos">{t('route.upload.sidebar.done.button')}</Button>
                        </UI.Box>
                    </UI.Sidebar>
                </div>
            );
        }

        return (
            <Form registry={registry.form}>
                <InputFile
                    name="files"
                    registryField={registry.field}
                    registry={registry}
                    onFilesLoaded={onFilesLoaded}
                    onCancelLoading={onCancelLoading}
                    external={true}
                    isSaveParams={true}
                />
            </Form>
        );
    };

    const elStatusBar = useMemo(() => {
        return (
            <div className={cn('upload__status-bar')}>
                <div className={cn('upload__status-bar-item')}>
                    <span
                        data-index={1}
                        className={cn('upload__status-text', {
                            'upload__status-text_active': match?.params.component === 'upload-photo' && !images.length
                        })}
                    >
                        {t('route.upload.status.uploading')}
                    </span>
                </div>
                <span className={cn('upload__divider')} />
                <div className={cn('upload__status-bar-item')}>
                    <span
                        data-index={2}
                        className={cn('upload__status-text', {
                            'upload__status-text_active': images.length
                        })}
                    >
                        {t('route.upload.status.manage')}
                    </span>
                </div>
            </div>
        );
    }, [match?.params.component, JSON.stringify(images)]);

    const elHeader = useMemo(() => {
        if(!!images.length) {
            return <h3 className={cn('input__page-header')}>{t('route.upload.content.header')}</h3>;
        }
    }, [images.length]);

    return (
        <UI.Main className={cn('upload')}>
            {elStatusBar}
            {elContent()}
        </UI.Main>
    );
};

export default Upload;
