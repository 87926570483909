import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import axios from 'axios';
import { parse, stringify } from 'query-string';
import debounce from 'lodash.debounce';

import { useClassnames } from 'hook/use-classnames';
import { useCancelToken, useCancelTokens } from 'component/core/cancel-token';
import UI from 'component/ui';
import Subscribe from 'component/subscribe';
import SearchResult from 'component/search-result';
import Person from 'component/icon/person';
import history from 'component/core/history';
import Loader from 'component/loader';
import Input from 'component/form/input';
import InputEvents from 'component/form/input-events';
import InputClubs from 'component/form/input-clubs';
import InputLocation from 'component/form/input-location';
import InputInterval from 'component/form/input-interval';
import Button from 'component/button';
import Form, { useRegistry } from 'component/form';
import { getUserInfo } from 'component/api/account';
import { INormalizeObject } from 'component/helper/types/normalize-object';

import { Data as ISubscription } from 'component/api/types/api/subscription/subscription/create/post/code-200';
import { Data as UserData } from 'component/api/types/api/account/user-info/get/code-200';

import { normalizeObject } from 'component/helper/normalize-object';

import style from './index.pcss';
import useIntersect from 'hook/use-intersect';
import api from 'src/api';
import { Event, EventsSearchFilter } from 'src/api/events/types';
import { Page } from 'src/api/base';
import { Photo, PhotosSearchFilter } from 'src/api/photos/types';
import { PersonSubscription } from 'src/api/persons/types';

const EVENTS_LIMIT = 10;

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const getDate = (): { currentDate: string, prevDate: string } => {
    const date = new Date();
    const currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    date.setDate(date.getDate() - 7);
    const prevDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return { currentDate, prevDate };
};

const Photographer = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, listToken] = useCancelTokens(2);
    const { form, field } = useRegistry();

    const { id }: { id: string } = useParams();

    const init = useRef(false);

    const [validity, setValidity] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [listPending, setListPending] = useState<boolean>(false);
    const [listPendingBefore, setListPendingBefore] = useState<boolean>(false);
    const [person, setPerson] = useState<UserData | null>(null);
    const [requested, setRequested] = useState<boolean>(false);
    const [list, setList] = useState<Array<Event>>([]);
    const [eventCount, setEventCount] = useState<number>(0);
    const [subscription, setSubscription] = useState<number | null>(null);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    // Last photos
    const photosToken = useCancelToken();
    const [totalPhotos, setTotalPhotos] = useState<number>(0);
    const [lastPhotos, setLastPhotos] = useState<Array<Photo>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isNextPage, setIsNextPage] = useState<boolean>(true);

    const [isEventListLoading, setIsEventListLoading] = useState<boolean>(false);
    const [isEventListLoadMore, setIsEventListLoadMore] = useState<boolean>(false);

    const _request = (): void => {
        setPending(true);

        getUserInfo({
            params: {
                user_id: id
            },
            cancelToken: token.new()
        })
            .then((resp) => {
                setPerson(resp);
                setPending(false);
                setRequested(true);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    console.error(err);

                    setPending(false);
                    setRequested(true);
                }
            });
    };

    const _requestPhotos = (): void => {
        const { currentDate, prevDate } = getDate();
        const page: Page = {
            pageNumber: 1,
            pageSize: 5
        };

        const filter: PhotosSearchFilter = {
            date_after: prevDate,
            date_before: currentDate,
            photographer_id: Number(id)
        };

        api.photos.getPhotosList(page, filter)
            .then((resp) => {
                setLastPhotos(resp.data.results);
                setTotalPhotos(resp.data.count);
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    useEffect(() => {
        if (isEventListLoading || isEventListLoadMore) {
            const page: Page = {
                pageNumber: currentPage,
                pageSize: EVENTS_LIMIT
            };

            const filter: EventsSearchFilter = {
                is_empty: 0,
                photographer_id: Number(id),
                ...(queryParams.person_name && { search: queryParams.person_name }),
                ...(queryParams.event_id && { event_id: queryParams.event_id }),
                ...(queryParams.location_id && { location_id: queryParams.location_id })
            };

            if (isNextPage) {
                api.events.getEventsList(page, filter)
                    .then((resp) => {
                        setList(
                            isEventListLoadMore
                                ? [...list, ...resp.data.results]
                                : resp.data.results
                        );
                        setEventCount(resp.data.count);
                        // setSubscription(resp.subscription || null);
                        setListPending(false);
                        setListPendingBefore(false);
                        setIsEventListLoading(false);
                        setIsEventListLoadMore(false);
                        if (resp.data.next !== null) {
                            setCurrentPage((prev) => prev + 1);
                        } else {
                            setIsNextPage(false);
                        }
                    })
                    .catch((err) => {
                        if(!axios.isCancel(err)) {
                            setListPending(false);
                            setListPendingBefore(false);
                            setIsEventListLoading(false);
                            setIsEventListLoadMore(false);
                        }
                    });
            }
        }
    }, [isEventListLoading, isEventListLoadMore]);

    const _requestSubscription = () => {
        api.photographers.getPhotographerItem(Number(id))
            .then((resp) => {
                if (resp.data.subscription) {
                    setSubscription(resp.data.subscription.id || null);
                }
            });
    };

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        _request();
        setIsEventListLoading(true);
        _requestPhotos();
        _requestSubscription();

        return () => {
            token.remove();
            listToken.remove();
            photosToken.remove();
        };
    }, [id]);

    useEffect(() => {
        if(form.checkValidity()) {
            setCurrentPage(1);
            setIsNextPage(true);
            setIsEventListLoading(true);
        }
    }, [JSON.stringify(queryParams)]);

    const onChangeForm = debounce(useCallback(() => {
        const payload = form.getPayload();
        const data = {
            ...(payload.name && { person_name: payload.name }),
            ...(payload.location && { location_id: payload.location.value }),
            ...(payload.event?.value && { event_id: payload.event.value })
        };

        history.replace({
            search: stringify(data, {
                arrayFormat: 'none'
            }),
            state: {
                noScroll: true
            }
        });
    }, [validity, JSON.stringify(form.getPayload()), JSON.stringify(person)]), 300);

    const onReset = useCallback(() => {
        form.clearForm();
        window.scrollTo(0, 0);

        setCurrentPage(1);
        setIsNextPage(true);
        setIsEventListLoading(true);
    }, []);

    const  elSubscription = useMemo(() => {
        if(requested && !pending && person?.id) {
            return <Subscribe type="user" subscription={subscription} photographerId={person?.id} persons={[person?.id]} className={cn('photographer__subscription')} />;
        }
    }, [JSON.stringify(person), requested, person, JSON.stringify(subscription)]);

    const elImageBlock = useMemo(() => {
        if(pending) {
            return <Loader />;
        }

        // if(person?.photo_url) {
        //     return (
        //         <div className={cn('photographer__image-block')}>
        //             <img alt={person.first_name} src={person.photo_url} className={cn('photographer__image')} />
        //         </div>
        //     );
        // }

        return (
            <div className={cn('photographer__image-empty')}>
                <Person className={cn('photographer__empty-icon')} />
            </div>
        );
    }, [JSON.stringify(person), pending]);

    const $LoadMoreEvent = useIntersect((entry) => {
        if(entry.isIntersecting && isNextPage) {
            setIsEventListLoadMore(true);
        }
    }, {
        rootMargin: '500px 0px'
    });

    const elLoadMoreEvent = () => {
        if (!isEventListLoading && !isEventListLoadMore && list.length) {
            if (eventCount > list.length) {
                return <Loader ref={$LoadMoreEvent} />;
            }
        }
    };

    const elList = useMemo(() => {
        if(listPending) {
            return (
                <UI.Box padding={true} className={cn('photographer__empty')}>
                    <Loader />
                </UI.Box>
            );
        }

        if(list?.length && !listPending) {
            return (
                <div className={cn('photographer__results')}>
                    {list.map((item, index) => (
                        <SearchResult
                            photo_attachments={item.photos}
                            key={index}
                            title={item.name}
                            place={item.location_name}
                            date={item.date}
                            link={`/events/${item.id}`}
                            query={`photographer_id=${id}`}
                            eventId={item.id}
                            photographerId={Number(id)}
                        />
                    ))}
                    {elLoadMoreEvent()}
                </div>
            );
        }

        return (
            <UI.Box padding={true} className={cn('photographer__empty')}>
                {t('route.photographer.results.empty')}
            </UI.Box>
        );
    }, [JSON.stringify(list), listPending, isNextPage, isEventListLoadMore, isEventListLoading]);

    const elPersonName = () => {
        const text = `${person?.first_name} ${person?.last_name}`.trim() || t('route.photographer.info.no-name');

        return <h4 className={cn('photographer__name')}>{text}</h4>;
    };

    const elLocation = () => {
        let defaultLocation = null;

        if(queryParams.location_id && queryParams.location_name) {
            defaultLocation = {
                value: queryParams.location_id,
                label: queryParams.location_name
            };
        }

        return (
            <InputLocation
                registry={field}
                clearable={true}
                defaultValue={defaultLocation}
                name="location"
                children="Город"
                direction="column"
                className={cn('photographer__input-block')}
            />
        );
    };

    const elLastPhoto = useMemo(() => {
        if (lastPhotos.length) {
            const { currentDate, prevDate } = getDate();

            return (
                <SearchResult
                    title={'Фото за последние 7 дней'}
                    link={'/persons/photos'}
                    photo_attachments={lastPhotos.map((item) => item.photo_url)}
                    photos_count={totalPhotos}
                    query={`date_from=${prevDate}&date_to=${currentDate}&photographer_id=${id}`}
                />
            );
        }
    }, [lastPhotos, totalPhotos]);

    return (
        <UI.Main className={cn('photographer')}>
            <div className={cn('photographer__grid')}>
                <div className={cn('photographer__sidebar')}>
                    <UI.Box padding={true} className={cn('photographer__box')}>
                        {elImageBlock}
                        <div className={cn('photographer__data')}>
                            <div className={cn('photographer__name-age')}>
                                {elPersonName()}
                                <span className={cn('photographer__age')}>{t('route.photographer.info.role')}</span>
                            </div>
                            {elSubscription}
                        </div>
                    </UI.Box>
                    <Form
                        registry={form}
                        onChangeValidity={setValidity}
                        onChange={onChangeForm}
                    >
                        <UI.Box padding={true} className={cn('photographer__box')}>
                            <UI.BoxHeader>{t('route.photographer.sidebar.header')}</UI.BoxHeader>
                            <Input
                                registry={field}
                                name="name"
                                defaultValue={queryParams.person_name}
                                type="text"
                                direction="column"
                                children={t('global.form.items.person')}
                                className={cn('photographer__input-block')}
                            />
                            <InputEvents
                                registry={field}
                                name="event"
                                default_id={queryParams.event_id}
                                clearable={true}
                                children={t('global.form.items.event')}
                                direction="column"
                                className={cn('photographer__input-block')}
                                photographer_id={Number(id)}
                            />
                            {elLocation()}
                            <Button
                                onClick={onReset}
                                type="reset"
                                className={cn('photographer__button-submit')}
                                disabled={pending}
                            >
                                {t('route.photographer.button-reset')}
                            </Button>
                        </UI.Box>
                    </Form>
                </div>
                <div className={cn('photographer__content')}>
                    {elLastPhoto}
                    {elList}
                </div>
            </div>
        </UI.Main>
    );
};

// tslint:disable-next-line:max-file-line-count
export default Photographer;
